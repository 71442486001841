import { useScreen, withNonNavigableScreenContainer } from "../../navigation/ScreenContainer";
import { ScreenView } from "../../components/ScreenView";
import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "../../lib/redux/Redux";
import { checkpointsCompleted, onboardingQuestionAnswered } from "../../lib/system/SystemSlice";
import { OnboardingScreenHeadline } from "../../components/onboarding/OnboardingHeadline";
import { BrowseKnownEntities, BrowseKnownEntitiesSearchBar } from "../../components/BrowseKnownEntities";
import { View } from "react-native";
import { useScreenHeaderDimensions } from "../../components/ScreenHeaders";
import { globalStyleConstants } from "../../components/GlobalStyles";
import { onboardingScreenHeader } from "../../components/onboarding/OnboardingScreenHeader";
import { OnboardingCtaButton } from "../../components/onboarding/OnboardingCtaButton";
import { BottomNotch } from "../../components/BottomNotch";
import { bottomActionBarConstants } from "../../components/BottomActionBar";
import { smallScreenBreakpoint } from "../../components/Responsive";
import { Separator } from "../../components/Separator";
import { Spacer } from "../../components/Spacer";
import { useKnownEntities } from "../../lib/users/UsersSelectors";
import { navToNextOnboardingScreen } from "../../navigation/NavThunks";

const strings = {
  cta: "Next",
  headline: "Which authors + sites do you want to follow?",
  subhead:
    "When you search, we'll prioritize recipes from authors + sites you follow. We'll also add newly published recipes to your feed.",
};

export const OnboardingCollectionsScreen = withNonNavigableScreenContainer("OnboardingCollectionsScreen", () => {
  const dispatch = useDispatch();
  const { nav } = useScreen();
  const { statusBarHeight } = useScreenHeaderDimensions();
  const [searchPhrase, setSearchPhrase] = useState("");
  const loading = useKnownEntities(searchPhrase).loading;

  useEffect(() => {
    // we display copy at the top of the screen that explains, so set this checkpoint as complete
    dispatch(checkpointsCompleted(["known_follow_explained"]));
  }, []);

  const onNext = useCallback(() => {
    dispatch(onboardingQuestionAnswered({ collectionSelectionDone: true }));
    dispatch(navToNextOnboardingScreen(nav, "replace"));
  }, [dispatch, nav.goTo]);

  const listHeader = (
    <View style={{ backgroundColor: "white", paddingTop: statusBarHeight + 2 * globalStyleConstants.unitSize }}>
      <OnboardingScreenHeadline headline={strings.headline} subHeadline={strings.subhead} paddingBottomUnits={1} />
      <Separator orientation="row" />
      <Spacer vertical={0.5} />
      <BrowseKnownEntitiesSearchBar
        searchPhrase={searchPhrase}
        setSearchPhrase={setSearchPhrase}
        paddingHorizontal="none"
      />
    </View>
  );

  return (
    <ScreenView
      header={onboardingScreenHeader}
      scrollView={false}
      paddingHorizontal={"none"}
      paddingVertical={"none"}
      loading={loading}
      backgroundColor="white"
    >
      <View
        style={{
          paddingBottom: bottomActionBarConstants.height,
          maxWidth: smallScreenBreakpoint,
          alignSelf: "center",
        }}
      >
        <BrowseKnownEntities
          searchPhrase={searchPhrase}
          analyticsContext="onboardingCollections"
          ListHeaderComponent={listHeader}
          disablePressEntity
        />
      </View>
      <OnboardingCtaButton cta="next" onPress={onNext} background="white" />
      <BottomNotch backgroundColor="white" />
    </ScreenView>
  );
});
