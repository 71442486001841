import { useSelector } from "../redux/Redux";
import {
  AuthedUser,
  NumericUserCheckpoint,
  RegisteredUser,
  SystemSettings,
  UserCheckpoint,
} from "@eatbetter/users-shared";
import { RootState } from "../redux/RootReducer";
import { secondsBetween, UserId } from "@eatbetter/common-shared";
import { is3rdPartySignInMethod } from "./SystemSlice";
import { Platform } from "react-native";
import { mergeItemWithUpdates } from "../redux/ItemWithUpdates";
import { createSelector1, createSelector2 } from "../redux/CreateSelector";
import { AppleFullName } from "../Deps";

export const useAuthStatus = () => useSelector(s => s.system.authStatus);
export const useAuthError = () => useSelector(s => s.system.preAuthData.authError);
export const usePending3rdPartySignIn = () =>
  useSelector(s => is3rdPartySignInMethod(s.system.preAuthData.signInMethod));
export const useUserAccessLevel = () => useSelector(s => s.system.authedUser.data?.access);

export const selectIsAnonymousUser = (s: RootState) => s.system.authedUser.data?.isRegistered === false;

export const useIsAnonymousUser = () => useSelector(selectIsAnonymousUser);
export const useAuthedUserId = () => useSelector(s => s.system.authedUser.data?.userId);
export const useAuthedUserHouseholdOrUserId = () =>
  useSelector(s => s.system.authedUser.data?.householdId ?? s.system.authedUser.data?.userId);

export const useAuthedUser = (): AuthedUser | undefined => {
  const u = useSelector(s => s.system.authedUser);
  return u.data;
};

export const useAnonymousUserCreated = (): boolean => {
  return useSelector(s => !!s.system.anonymousUserCreated);
};

export const useLaunchCarouselCompleted = (): boolean => {
  return useSelector(s => !!s.system.launchCarouselCompleted);
};

export const useHomeScreenOnboardingShouldBeShown = (): boolean => {
  return useSelector(s => !!s.system.showHomeScreenOnboarding);
};

export const useAppSignInLinkForWeb = () => {
  const r = useSelector(s => s.system.WEB_ONLY_appSignInLink);
  // this should never be set for app, but just in case
  return Platform.OS === "web" ? r : undefined;
};

export const useAppMetadata = () => useSelector(s => s.system.appMetadata.data);

export const useRegisteredUser = (): RegisteredUser | undefined => {
  const authedUser = useAuthedUser();
  if (authedUser?.isRegistered === false) {
    return undefined;
  }

  return authedUser;
};

/**
 * Can be used to determine if it's safe to navigate from a non-user driven action, such as
 * a modal that shows up when condition X is met and that condition is not explicitly driven by a user.
 */
export const useSafeForNonUserDrivenNavigation = () =>
  useSelector(state => {
    return !state.system.requestedNavAction && !state.system.tappedNotification;
  });

export const usePushPermission = () => useSelector(s => s.system.pushPermission);
export const usePushLastPrompted = () => useSelector(s => s.system.userLastPromptedForPush);

export const useTabsMounted = () => useSelector(s => s.system.navigation.tabsMounted);

export const useRequestedNavAction = () => useSelector(s => s.system.requestedNavAction);

export const useDoAppReset = () => useSelector(s => s.system.doAppReset);

export const selectSessionStartTime = (s: RootState) => s.system.session.start;

export const selectUserId = (s: RootState): UserId | undefined => s.system.authedUser.data?.userId;

export const useSystemSettings = () => useSelector(s => s.system.systemSettings);

export const useSystemSetting = <T extends keyof SystemSettings>(setting: T): SystemSettings[T] =>
  useSelector(s => {
    return s.system.systemSettings[setting];
  });

export const useTappedNotification = () => useSelector(s => s.system.tappedNotification);

export const useSessionStartTime = () => useSelector(s => s.system.session.start);

// diagnosticModeEndTime will be cleard by a reactor, so the exact time doesn't matter. Just return true
// if the time is set.
export const useIsDiagnosticModeEnabled = () => useSelector(s => !!s.system.diagnosticModeEndTime);

export const useEmailLinkSignInAddress = () => useSelector(s => s.system.preAuthData.email);
export const useEmailSigninLinkPending = () => useSelector(s => s.system.preAuthData.emailSigninLinkReceived);

export const useAppFocused = () => {
  return useSelector(s => {
    if (Platform.OS === "web") {
      // there might be a web version of this for the tab having focus, but taking the easy way out for now
      return true;
    }

    return !!s.system.appFocused;
  });
};

export const useUserCreateData = () => useSelector(s => s.system.preAuthData.createData);

const selectUserCreateDataName = createSelector2(
  s => s.system.preAuthData.createData,
  s => s.system.preAuthData.appleName,
  (preAuthData, appleName) => {
    if (preAuthData?.name) {
      return preAuthData.name;
    }

    if (appleName) {
      return getNameFromAppleFullName(appleName);
    }

    return undefined;
  }
);

export function getNameFromAppleFullName(appleName: AppleFullName): string | undefined {
  const appleFirst = appleName?.nickname ?? appleName?.givenName ?? "";
  const appleLast = appleName?.familyName ?? "";

  if (appleFirst || appleLast) {
    return `${appleFirst} ${appleLast}`.trim();
  }

  return undefined;
}

export const usePreAuthDataName = () => useSelector(selectUserCreateDataName);

export const selectHasFullWebAccess = (s: RootState) => {
  const access = s.system.authedUser.data?.access;
  return s.system.systemSettings.superAdmin || access === "admin" || access === "superAdmin";
};

export const useHasFullWebAccess = () => useSelector(selectHasFullWebAccess);

const householdChangedHighFrequencyDuration = 300;

/**
 * When we detect that household members have changed, we want to poll more frequently to pick up
 * the updated recipes, grocery lists, and cooking sessions.
 */
export const selectFetchIntervalForHouseholdUpdate = (s: RootState, defaultSeconds: number) => {
  if (!s.system.householdMembersChangedTime) {
    return defaultSeconds;
  }

  // poll more frequently at first, then slow it down in case the backend is slow
  const delta = secondsBetween(s.system.householdMembersChangedTime, s.system.time);

  if (delta < 30) {
    return 15;
  }

  if (delta < 120) {
    return 30;
  }

  if (delta < householdChangedHighFrequencyDuration) {
    return 60;
  }

  return defaultSeconds;
};

export const selectIsHouseholdUpdatePeriodActive = (s: RootState) => {
  return (
    !!s.system.householdMembersChangedTime &&
    secondsBetween(s.system.householdMembersChangedTime, s.system.time) <= householdChangedHighFrequencyDuration
  );
};

export const selectIsHouseholdUpdatePeriodComplete = (s: RootState) => {
  return (
    !!s.system.householdMembersChangedTime &&
    secondsBetween(s.system.householdMembersChangedTime, s.system.time) > householdChangedHighFrequencyDuration
  );
};

export const selectCheckpoints = createSelector1(
  s => s.system.userCheckpoints,
  checkpoints => {
    return mergeItemWithUpdates(checkpoints);
  }
);

export const selectCheckpointCompleted = (s: RootState, checkpoint: UserCheckpoint): boolean => {
  const merged = selectCheckpoints(s);
  return !!merged[checkpoint];
};

export const useCheckpointCompleted = (checkpoint: UserCheckpoint): boolean =>
  useSelector(s => selectCheckpointCompleted(s, checkpoint));

export const useNumericCheckpointValue = (checkpoint: NumericUserCheckpoint): number | undefined => {
  return useSelector(s => {
    const merged = selectCheckpoints(s);
    return merged[checkpoint] ?? undefined;
  });
};

const selectAllCompletedCheckpoints = createSelector1(
  s => selectCheckpoints(s),
  checkpoints => {
    return Object.entries(checkpoints).flatMap(e => {
      const [key, value] = e;
      if (value) {
        return key;
      } else {
        return [];
      }
    }) as UserCheckpoint[];
  }
);

const selectAllNumericCheckpoints = createSelector1(
  s => selectCheckpoints(s),
  checkpoints => {
    return Object.fromEntries(Object.entries(checkpoints).filter(e => typeof e[1] === "number"));
  }
);

export const useAllCompletedCheckpoints = () => useSelector(selectAllCompletedCheckpoints);
export const useAllNumericCheckpoints = () => useSelector(selectAllNumericCheckpoints);

export const useCookingSessionFontScale = () => useSelector(s => s.system.deviceSettings.cookingSessionFontScale);
