import { useScreen, withNonNavigableScreenContainer } from "../navigation/ScreenContainer";
import { ScreenView } from "../components/ScreenView";
import { navTree } from "../navigation/NavTree";
import { Animated, Image, ImageSourcePropType, StyleSheet, View } from "react-native";
import { defaultScreenAspectRatio, defaultScreenWidth, useResponsiveDimensions } from "../components/Responsive";
import { BottomActionBar, bottomActionBarConstants } from "../components/BottomActionBar";
import { TBody } from "../components/Typography";
import { Spacer } from "../components/Spacer";
import { TabView, TabViewRoute } from "../components/TabView";
import { useCallback, useEffect, useRef, useState } from "react";
import { defaultTimeProvider, DurationMs, range, switchReturn } from "@eatbetter/common-shared";
import { useScreenHeaderDimensions } from "../components/ScreenHeaders";
import { globalStyleColors, globalStyleConstants } from "../components/GlobalStyles";
import { Haptics } from "../components/Haptics";
import { useBottomTabBarDimensions } from "../navigation/TabBar";
import { useDispatch } from "../lib/redux/Redux";
import { analyticsEvent } from "../lib/analytics/AnalyticsThunks";
import { reportLaunchCarouselCompleted, reportLaunchCarouselStarted } from "../lib/analytics/AnalyticsEvents";
import { useAppFocused } from "../lib/system/SystemSelectors";
import { DeglazeLogoOverlay, getDeglazeLogoOverlayHeight } from "../components/DeglazeLogoOverlay";
import { Splash } from "../components/Splash";
import { ContainerFadeIn } from "../components/Containers";

// Images
const libraryPhone = require("../assets/first_open/library_phone.png");
const libraryBg = require("../assets/first_open/library_bg.png");
const xrayPhone = require("../assets/first_open/xray_phone.png");
const xrayBg = require("../assets/first_open/xray_bg.png");
const groceryPhone = require("../assets/first_open/grocery_phone.png");
const groceryBg = require("../assets/first_open/grocery_bg.png");
const searchPhone = require("../assets/first_open/search_phone.png");
const searchBg = require("../assets/first_open/search_bg.png");

const strings = {
  start: "Get Started",
  next: "Next",
  getStarted: "Get Started",
  library: "Import and organize recipes from\nwebsites, Instagram, TikTok, and more. ",
  xray: "AI-powered X-Ray mode gives you\njust the parts you need.",
  grocery: "Save time at the store with an\nautomatically organized grocery list.",
  search: "Get meal inspiration from the\ntop recipe authors in the world.",
};

// The below constant values are for the notch phone. Given these values and the current screen aspect ratio, we can calculate
// the correct values for the current device.
const constants = {
  // Aspect ratios are (height / width)
  defaultBgAspectRatio: 1.3,
  defaultPhoneWidthAsPercentageOfScreen: 0.62,
  phoneAspectRatio: 2.03,
  // This one is different because it has annotation arrows that are outside the phone boundary
  xrayPhoneAspectRatio: 1.56,
  xrayPhoneWidthAsPercentageOfScreen: 0.825,
  xrayPhoneOffsetY: -8,
  phoneFadeDelayMs: 0,
  phoneFadeDurationMs: 0,
  headlineFadeDelayMs: 0,
  headlineFadeDurationMs: 0,
};

const routeKeys = ["library", "xray", "grocery", "search"] as const;
type RouteKey = (typeof routeKeys)[number];
const isRouteKey = (str: string): str is RouteKey => routeKeys.some(i => i === str);

const routes: TabViewRoute<RouteKey>[] = [
  { key: "library", title: "Library" },
  { key: "xray", title: "X-ray" },
  { key: "grocery", title: "Grocery" },
  { key: "search", title: "Search" },
];

export const LaunchCarouselScreen = withNonNavigableScreenContainer("LaunchCarouselScreen", () => {
  const { nav } = useScreen();
  const dispatch = useDispatch();

  const [index, setIndex] = useState(0);
  const timeTracker = useRef<SlideTimeTracker>();

  useEffect(() => {
    timeTracker.current = new SlideTimeTracker();
    dispatch(analyticsEvent(reportLaunchCarouselStarted()));

    return () => {
      const results = timeTracker.current?.getDurationsAndBackActions();
      dispatch(
        analyticsEvent(
          reportLaunchCarouselCompleted({
            durations: results?.durations,
            backActions: results?.backActions ?? 0,
          })
        )
      );
    };
  }, []);

  useEffect(() => {
    timeTracker.current?.indexChanged(index);
  }, [index]);

  const appFocused = useAppFocused();

  useEffect(() => {
    if (appFocused) {
      timeTracker.current?.appForegrounded();
    } else {
      timeTracker.current?.appBackgrounded();
    }
  }, [appFocused]);

  const onPressNext = () => {
    Haptics.feedback("itemStatusChanged");
    setIndex(prev => prev + 1);
  };

  const navToSignIn = () => nav.goTo("reset", navTree.get.screens.signIn);

  const onPressGetStarted = () => {
    timeTracker.current?.complete();
    Haptics.feedback("itemStatusChanged");
    navToSignIn();
  };

  const onIndexChange = useCallback((index: number) => {
    setIndex(index);
  }, []);

  const onPressBack = useCallback(() => {
    Haptics.feedback("itemStatusChanged");
    setIndex(prev => Math.max(prev - 1, 0));
  }, [setIndex]);

  const renderRoute = useCallback(
    (routeProps: { route: TabViewRoute }) => {
      const routeKey = routeProps.route.key;
      if (!isRouteKey(routeKey)) {
        throw new Error("Unexpected route key: " + routeKey);
      }

      return <CarouselRoute type={routeKey} currentIndex={index} onPressBack={onPressBack} />;
    },
    [onPressBack, index]
  );

  const [showSplash, setShowSplash] = useState(true);

  const onGetStarted = useCallback(() => {
    setShowSplash(false);
  }, [setShowSplash]);

  return (
    <ScreenView scrollView={false} paddingVertical={"none"} paddingHorizontal={"none"}>
      <DeglazeLogoOverlay onPressBack={index > 0 ? onPressBack : undefined} />
      <TabView routes={routes} index={index} onIndexChange={onIndexChange} renderRoute={renderRoute} tabBar="none" />
      <ProgressBar currentIndex={index} />
      <BottomActionBar
        primaryAction={
          routes[index + 1]
            ? { actionText: strings.next, onPressAction: onPressNext }
            : { actionText: strings.getStarted, onPressAction: onPressGetStarted }
        }
        containerBackgroundColor="transparent"
      />
      {showSplash && <SplashOverlay onPress={onGetStarted} />}
    </ScreenView>
  );
});

const SplashOverlay = (props: { onPress: () => void }) => {
  const { bottomTabBarHeight } = useBottomTabBarDimensions();

  const fadeAnim = useRef(new Animated.Value(1)).current;

  const onPress = () => {
    // Start fade-out on press
    Animated.timing(fadeAnim, {
      toValue: 0,
      duration: 500,
      useNativeDriver: true,
    }).start(props.onPress);
  };

  return (
    <Animated.View style={[StyleSheet.absoluteFill, { zIndex: 1000, opacity: fadeAnim }]}>
      <Splash />
      <ContainerFadeIn
        delay={300}
        duration={800}
        style={{
          position: "absolute",
          bottom: bottomTabBarHeight + globalStyleConstants.defaultPadding,
          left: 0,
          right: 0,
        }}
      >
        <BottomActionBar
          disableSnapToBottom
          containerBackgroundColor="transparent"
          primaryAction={{ type: "acknowledge", actionText: strings.getStarted, onPressAction: onPress }}
        />
      </ContainerFadeIn>
    </Animated.View>
  );
};

const CarouselRoute = (props: { type: RouteKey; currentIndex: number; onPressBack: () => void }) => {
  const { statusBarHeight } = useScreenHeaderDimensions();
  const { width: screenWidth } = useResponsiveDimensions();

  const { background, phone, headline, aspectRatio, width, phoneOffsetY } = switchReturn(props.type, {
    library: {
      background: libraryBg,
      phone: libraryPhone,
      headline: strings.library,
      aspectRatio: constants.phoneAspectRatio,
      width: constants.defaultPhoneWidthAsPercentageOfScreen,
    },
    xray: {
      background: xrayBg,
      phone: xrayPhone,
      headline: strings.xray,
      aspectRatio: constants.xrayPhoneAspectRatio,
      width: constants.xrayPhoneWidthAsPercentageOfScreen,
      phoneOffsetY: constants.xrayPhoneOffsetY,
    },
    grocery: {
      background: groceryBg,
      phone: groceryPhone,
      headline: strings.grocery,
      aspectRatio: constants.phoneAspectRatio,
      width: constants.defaultPhoneWidthAsPercentageOfScreen,
    },
    search: {
      background: searchBg,
      phone: searchPhone,
      headline: strings.search,
      aspectRatio: constants.phoneAspectRatio,
      width: constants.defaultPhoneWidthAsPercentageOfScreen,
    },
  });

  const routeIndex = routes.findIndex(i => i.key === props.type);
  const isFocused = props.currentIndex === routeIndex;

  return (
    <View
      style={{
        paddingTop: statusBarHeight + getDeglazeLogoOverlayHeight(screenWidth),
        paddingHorizontal: globalStyleConstants.defaultPadding,
      }}
    >
      <BackgroundPhoto source={background} />
      <PhoneWithScreenshot
        source={phone}
        aspectRatio={aspectRatio}
        widthAsPercentageOfScreen={width}
        phoneOffsetY={phoneOffsetY}
        isFocused={isFocused}
      />
      <Spacer vertical={2 * globalStyleConstants.unitSize - (phoneOffsetY ? 11 : 0)} unit="pixels" />
      <HeadlineText text={headline} isFocused={isFocused} />
    </View>
  );
};

const PhoneWithScreenshot = (props: {
  source: ImageSourcePropType;
  aspectRatio: number;
  widthAsPercentageOfScreen: number;
  phoneOffsetY?: number;
  isFocused: boolean;
}) => {
  const { screenAspectRatio, width: screenWidth } = useResponsiveDimensions();

  const widthAsPercentageOfScreen = (props.widthAsPercentageOfScreen / defaultScreenAspectRatio) * screenAspectRatio;
  const phoneWidth = widthAsPercentageOfScreen * screenWidth;
  const phoneHeight = phoneWidth * props.aspectRatio;
  const phoneOffset = props.phoneOffsetY ? { transform: [{ translateY: props.phoneOffsetY }] } : {};

  return (
    <View
      style={[{ width: "100%", alignItems: "center" }, phoneOffset]}
      // delay={constants.phoneFadeDelayMs}
      // duration={constants.phoneFadeDurationMs}
      // trigger={props.isFocused}
    >
      <Image resizeMode="contain" source={props.source} style={{ width: phoneWidth, height: phoneHeight }} />
    </View>
  );
};

const BackgroundPhoto = (props: { source: ImageSourcePropType }) => {
  const { width: screenWidth, screenAspectRatio } = useResponsiveDimensions();

  const bgAspectRatio = (constants.defaultBgAspectRatio / defaultScreenAspectRatio) * screenAspectRatio;

  return (
    <View style={{ position: "absolute", top: 0, left: 0, right: 0 }}>
      <Image
        resizeMode="cover"
        source={props.source}
        style={{ width: screenWidth, height: bgAspectRatio * screenWidth }}
      />
    </View>
  );
};

const HeadlineText = (props: { text: string; isFocused: boolean }) => {
  const { width: screenWidth } = useResponsiveDimensions();

  const defaultFontScale = 1.1;
  const fontScale = Math.max(defaultFontScale, Math.min(1.6, (screenWidth / defaultScreenWidth) * defaultFontScale));

  return (
    <View
    // delay={constants.headlineFadeDelayMs}
    // duration={constants.headlineFadeDurationMs}
    // trigger={props.isFocused}
    >
      <TBody align="center" enableFontScaling="upOnly" scale={fontScale} numberOfLines={2} adjustsFontSizeToFit>
        {props.text}
      </TBody>
    </View>
  );
};

const ProgressBar = (props: { currentIndex: number }) => {
  const { bottomNotchHeight } = useBottomTabBarDimensions();

  return (
    <View
      style={{
        position: "absolute",
        left: 0,
        right: 0,
        bottom: bottomNotchHeight + bottomActionBarConstants.height + globalStyleConstants.unitSize,
      }}
    >
      <View style={{ flexDirection: "row", alignSelf: "center" }}>
        {range(routes.length - 1).map(i => {
          return (
            <View key={routes[i]!.key} style={{ flexDirection: "row" }}>
              {i !== 0 && <Spacer horizontal={8} unit="pixels" />}
              <ProgressBarCircle filled={i === props.currentIndex} />
            </View>
          );
        })}
      </View>
    </View>
  );
};

const ProgressBarCircle = (props: { filled: boolean }) => {
  const backgroundColor = props.filled ? globalStyleColors.colorAccentCool : "rgba(0,0,0,0.2)";

  return <View style={{ width: 6, height: 6, borderRadius: 6, backgroundColor }} />;
};

class SlideTimeTracker {
  private currentIndex = 0;
  private backgrounded = false;
  private start = defaultTimeProvider();
  private backActions = 0;
  private readonly durations: number[][] = [[]];

  indexChanged(idx: number) {
    if (idx < 0 || idx === this.currentIndex) {
      return;
    }

    if (idx < this.currentIndex) {
      this.backActions++;
    }

    // there is a mismatch in the initialization here and the push of duration below.
    // this is because 0 is initialized in the initial setter (and zero is the default start index)
    // but we need the array for the new index to exist so we can push in appBackgrounded
    if (!this.durations[idx]) {
      this.durations[idx] = [];
    }

    const duration = defaultTimeProvider() - this.start;
    this.durations[this.currentIndex]?.push(duration);

    this.currentIndex = idx;
    this.start = defaultTimeProvider();
  }

  complete() {
    const duration = defaultTimeProvider() - this.start;
    this.durations[this.currentIndex]?.push(duration);
  }

  appBackgrounded() {
    if (!this.backgrounded) {
      const duration = defaultTimeProvider() - this.start;
      this.durations[this.currentIndex]?.push(duration);
      this.backgrounded = true;
    }
  }

  appForegrounded() {
    if (this.backgrounded) {
      this.start = defaultTimeProvider();
      this.backgrounded = false;
    }
  }

  getDurationsAndBackActions(): { durations: DurationMs[]; backActions: number } {
    const durations: DurationMs[] = [];
    for (let i = 0; i < this.durations.length; i++) {
      const durationsForIndex = this.durations[i];
      if (durationsForIndex) {
        durations.push(durationsForIndex.reduce((a, b) => a + b) as DurationMs);
      } else {
        durations.push(0 as DurationMs);
      }
    }

    return { durations, backActions: this.backActions };
  }
}
