import { AutoSaveTextEdit, PersistTextHandler, useAutoSaveTextEdit } from "./UseAutoSaveTextEdit";
import { GroceryListItemId } from "@eatbetter/lists-shared";
import { useListItem, useListRecipe } from "../lists/ListsSelectors";
import { groceryListItemTextUpdated } from "../lists/ListsSlice";
import { useScaled } from "./UseScaled";
import { UnitConversion } from "@eatbetter/items-shared";

export const useGroceryItemTextEdit = (id: GroceryListItemId): AutoSaveTextEdit => {
  const listItem = useListItem(id);
  const recipeListItem = listItem.type === "recipe" ? listItem : undefined;
  const listRecipe = useListRecipe(recipeListItem?.recipeInstanceId);

  //SCALE-TODO: get from user settings
  const units: UnitConversion = "original";

  //SCALE-TODO: implement editing + scaling logic, i.e. if edited, we stop scaling
  //SCALE-TODO-DISPATCH: how do we refresh the text input when scaling is updated and reconcile that with persisting?
  const scale = listRecipe?.scale ?? 1;
  const scaledPersistedText = useScaled(recipeListItem, scale, units);
  const persistedText =
    recipeListItem && scaledPersistedText.length > 0
      ? scaledPersistedText.reduce((acc, curr) => (acc += curr.text), "")
      : listItem.text;

  const setPersistedText: PersistTextHandler = (text, dispatch, setPending, callSuccessful) => {
    if (!isValid(text)) {
      callSuccessful(false);
      setPending(false);
      return;
    }

    dispatch(groceryListItemTextUpdated({ id, text }));
    callSuccessful(true);
    setPending(false);
  };

  return useAutoSaveTextEdit(persistedText, setPersistedText, { setPendingOnTextChange: true });
};

function isValid(text: string): boolean {
  return text.trim() !== "";
}
