import React, { PropsWithChildren, ReactNode, SetStateAction, useCallback, useEffect, useMemo, useState } from "react";
import { useScreen, withScreenContainer } from "../navigation/ScreenContainer";
import { ScreenView } from "../components/ScreenView";
import { TBody, THeading1, THeading2 } from "../components/Typography";
import { useDispatch } from "../lib/redux/Redux";
import {
  groceryListRecipeScaleUpdatedClient,
  groceryListSortChanged,
  spotlightGroceryIconChanged,
} from "../lib/lists/ListsSlice";
import {
  SortedListSections,
  useListRecipe,
  useSortedListSections,
  useSpotlightGroceryIcon,
} from "../lib/lists/ListsSelectors";
import { GroceryListItemId, GroceryListSort, RecipeInstanceId } from "@eatbetter/lists-shared";
import { Spacer } from "../components/Spacer";
import {
  completeAllGroceryListItems,
  deleteRecipeFromGroceryList,
  groceryListItemStatusUpdatedClient,
  loadGroceryLists,
} from "../lib/lists/ListsThunks";
import { LayoutAnimation, SectionList, StyleSheet, View } from "react-native";
import { AddItemButton } from "../components/Buttons";
import { globalStyleColors, globalStyleConstants } from "../components/GlobalStyles";
import { Haptics } from "../components/Haptics";
import { largeScreenBreakpoint, useResponsiveDimensions } from "../components/Responsive";
import { SegmentedControl, getSegmentedControlHeight } from "../components/SegmentedControl";
import { useTabRenderDelay } from "../lib/util/UseTabRenderDelay";
import { UserRecipeId } from "@eatbetter/recipes-shared";
import { switchReturn } from "@eatbetter/common-shared";
import { navTree } from "../navigation/NavTree";
import { HeaderProps, useHeaderScrollAnimation, useScreenHeaderDimensions } from "../components/ScreenHeaders";
import { scrollTo, useAnimatedRef, useAnimatedScrollHandler, useDerivedValue } from "react-native-reanimated";
import { useScrollToTop } from "@react-navigation/native";
import { GroceryItemStatusChangeHandler } from "../components/grocery/GroceryListItem";
import { useCheckpointCompleted } from "../lib/system/SystemSelectors";
import { getOptionsMenuHeight, OptionsMenu, OptionsMenuItem } from "../components/OptionsMenu";
import { useBottomSheet } from "./BottomSheetScreen";
import { reportGroceryLinkYourHouseholdButtonTapped } from "../lib/analytics/AnalyticsEvents";
import { analyticsEvent } from "../lib/analytics/AnalyticsThunks";
import { GroceryListSortView } from "../components/grocery/GroceryListSortView";
import { Alert } from "../components/Alert/Alert";
import { useScalingAndConversionsSheet } from "../components/recipes/ScalingAndConversions";
import { UnitConversion } from "@eatbetter/items-shared";

const config = {
  maxCompletedItems: 50,
  disableAutocomplete: true,
  recipeOptionsMenu: {
    sheetHeight: getOptionsMenuHeight(3),
  },
};

const strings = {
  header: "Groceries",
  initialState: {
    headline: "World's smartest grocery list",
    subhead: "You add, we organize. ✨",
    linkYourHousehold: ["Link your household", " to share one list\nwith everyone at home. 👪"],
  },
  emptyState: {
    headline: "All Clear",
    subhead: 'Press + below to add an item, or tap "Add to Groceries" from any recipe from your library.',
  },
  cardTitle: {
    completed: "Completed",
  },
  textInput: {
    placeholder: "e.g. Cherry tomatoes",
  },
  sortBy: {
    date: "By Date",
    category: "By Aisle",
    recipe: "By Recipe",
  },
  recipeOptionsMenu: {
    viewRecipe: "Open Recipe in Library",
    scaleRecipe: "Scale & Convert Recipe",
    removeRecipe: "Remove from Groceries",
  },
  noItems: "No items.",
  clearAllAlert: {
    title: "Clear all items?",
    message: "All items will be marked as completed.",
  },
};

const animationConfig = LayoutAnimation.create(75, "easeInEaseOut", "opacity");

export const GroceryListScreen = withScreenContainer("GroceryListScreen", () => {
  const dispatch = useDispatch();
  const [isRecipeExpanded, setIsRecipeExpanded] = useState<Set<RecipeInstanceId>>(new Set());
  const screen = useScreen();
  const shouldRender = useTabRenderDelay(250, screen.nav.focused);
  const spotlight = useSpotlightGroceryIcon();

  const onPullToRefresh = useCallback(() => {
    return dispatch(loadGroceryLists());
  }, [dispatch]);

  const [inputText, setInputText] = useState("");

  // The first time a user adds a recipe to grocery, we show a hint on the grocery tab icon. Once they visit this screen
  // we need to turn it off
  useEffect(() => {
    if (spotlight && screen.nav.focused) {
      dispatch(spotlightGroceryIconChanged(false));
    }
  }, [spotlight, screen.nav.focused]);

  const onStatusChange = useCallback<GroceryItemStatusChangeHandler>(
    async (id, status, groupCount, groupSwipe) => {
      LayoutAnimation.configureNext(animationConfig);
      dispatch(groceryListItemStatusUpdatedClient({ id, status, groupCount, groupSwipe }));
    },
    [dispatch]
  );

  const openAddSheet = useCallback(() => {
    screen.nav.modal(navTree.get.screens.groceryAddItem);
  }, [screen.nav.modal]);

  const openEditSheet = useCallback(
    (id: GroceryListItemId) => {
      screen.nav.modal(navTree.get.screens.groceryEditItem, { itemId: id });
    },
    [screen.nav.modal]
  );

  const openRecipeOptionsSheet = useCallback(
    (recipeId: UserRecipeId, recipeInstanceId: RecipeInstanceId) => {
      screen.nav.modal(navTree.get.screens.bottomSheet, {
        content: <RecipeOptionsMenu recipeId={recipeId} recipeInstanceId={recipeInstanceId} />,
        height: config.recipeOptionsMenu.sheetHeight,
      });
    },
    [screen.nav.modal]
  );

  // Triggers a swipe hint effect in the grocery list. We consume the focused signal so that we only trigger a render
  // one the affected GLI and not the entire list.
  const showSwipeHint = !useCheckpointCompleted("gliSwiped") && screen.nav.focused;

  return React.createElement(GroceryListScreenComponent, {
    inputText,
    onChangeText: setInputText,
    onPullToRefresh,
    onStatusChange,
    openAddSheet,
    openEditSheet,
    openRecipeOptionsSheet,
    shouldRender,
    isRecipeExpanded,
    setIsRecipeExpanded,
    showSwipeHint,
  });
});

interface Props {
  shouldRender: boolean;
  inputText: string;
  onChangeText: (s: string) => void;
  onPullToRefresh: () => Promise<void>;
  onStatusChange: GroceryItemStatusChangeHandler;
  openAddSheet: () => void;
  openEditSheet: (id: GroceryListItemId) => void;
  openRecipeOptionsSheet: (recipeId: UserRecipeId, recipeInstanceId: RecipeInstanceId) => void;
  isRecipeExpanded: Set<RecipeInstanceId>;
  setIsRecipeExpanded: React.Dispatch<SetStateAction<Set<RecipeInstanceId>>>;
  showSwipeHint: boolean;
}

export const GroceryListScreenComponent = React.memo((props: PropsWithChildren<Props>) => {
  const dispatch = useDispatch();
  const dimensions = useResponsiveDimensions();
  const sections = useSortedListSections();

  const setSortBy = useCallback(
    (targetSortBy: GroceryListSort) => {
      if (sections.activeSort !== targetSortBy) {
        dispatch(groceryListSortChanged(targetSortBy));
      }
    },
    [sections.activeSort, dispatch]
  );

  const onPressSortByCategory = useCallback(() => {
    setSortBy("category");
  }, [setSortBy]);

  const onPressSortByTime = useCallback(() => {
    setSortBy("time");
  }, [setSortBy]);

  const onPressSortByRecipe = useCallback(() => {
    setSortBy("recipe");
  }, [setSortBy]);

  const sortControlPaddingHorizontal = 16;
  const sortControlPaddingVertical = globalStyleConstants.unitSize;

  const renderSortControl = useCallback(() => {
    if (sections.timeSorted.pending.length === 0 && sections.timeSorted.completed.length === 0) {
      return null;
    }

    const width = Math.min(dimensions.width, largeScreenBreakpoint) - 2 * sortControlPaddingHorizontal;

    return (
      <View style={{ width: "100%", paddingVertical: sortControlPaddingVertical, alignItems: "center" }}>
        <SegmentedControl
          height="large"
          width={width}
          segments={[
            {
              label: strings.sortBy.category,
              onPress: onPressSortByCategory,
              isSelected: sections.activeSort === "category",
            },
            {
              label: strings.sortBy.date,
              onPress: onPressSortByTime,
              isSelected: sections.activeSort === "time",
            },
            {
              label: strings.sortBy.recipe,
              onPress: onPressSortByRecipe,
              isSelected: sections.activeSort === "recipe",
            },
          ]}
        />
      </View>
    );
  }, [
    dimensions.width,
    onPressSortByCategory,
    onPressSortByTime,
    onPressSortByRecipe,
    sections.activeSort,
    sections.timeSorted.pending.length,
    sections.timeSorted.completed.length,
  ]);

  const getSortControlHeight = useCallback(() => {
    return getSegmentedControlHeight("large") + 2 * sortControlPaddingVertical;
  }, [sortControlPaddingVertical]);

  const [categorySortedScrollY, onScrollCategorySorted] = useHeaderScrollAnimation();
  const [timeSortedScrollY, onScrollTimeSorted] = useHeaderScrollAnimation();
  const [recipeSortedScrollY, onScrollRecipeSorted] = useHeaderScrollAnimation();

  const animationProgress = switchReturn(sections.activeSort, {
    category: categorySortedScrollY,
    time: timeSortedScrollY,
    recipe: recipeSortedScrollY,
  });

  const { bareHeaderHeight } = useScreenHeaderDimensions();

  const categoryScrollRef = useAnimatedRef<SectionList>();
  const timeScrollRef = useAnimatedRef<SectionList>();
  const recipeScrollRef = useAnimatedRef<SectionList>();

  const scrollRefs = useMemo(() => {
    return {
      category: categoryScrollRef,
      time: timeScrollRef,
      recipe: recipeScrollRef,
    };
  }, [categoryScrollRef, timeScrollRef, recipeScrollRef]);

  useScrollToTop(scrollRefs[sections.activeSort]);

  // Synchronize scrolls across sort views to prevent header jumping
  useDerivedValue(() => {
    Object.entries(scrollRefs)
      .filter(([key]) => key !== sections.activeSort)
      .forEach(([_, ref]) => {
        scrollTo(
          ref,
          0,
          animationProgress.value < bareHeaderHeight ? animationProgress.value : bareHeaderHeight,
          false
        );
      });
  }, [scrollRefs, sections.activeSort, animationProgress, bareHeaderHeight]);

  const groceryViewOnScrolls = useMemo(() => {
    return { category: onScrollCategorySorted, time: onScrollTimeSorted, recipe: onScrollRecipeSorted };
  }, [onScrollCategorySorted, onScrollTimeSorted, onScrollRecipeSorted]);

  const completeAllPending = useCallback(() => {
    dispatch(completeAllGroceryListItems());
    Haptics.feedback("operationSucceeded");
  }, [dispatch]);

  const onPressClearAll = useCallback(() => {
    Alert.alert(strings.clearAllAlert.title, strings.clearAllAlert.message, [
      {
        type: "cancel",
        onPress: () => {},
      },
      {
        type: "delete",
        text: "Clear all",
        onPress: completeAllPending,
      },
    ]);
  }, [dispatch, completeAllPending]);

  const havePendingItems = sections.timeSorted.pending.length > 0;

  const screenHeader = useMemo<HeaderProps>(() => {
    return {
      type: "custom",
      style: "tabRoot",
      title: strings.header,
      animationConfig: {
        animationProgress,
      },
      subHeaderComponent: { render: renderSortControl, getHeight: getSortControlHeight },
      right: { type: "deleteAll", onPress: onPressClearAll, disabled: !havePendingItems },
    };
  }, [animationProgress, renderSortControl, getSortControlHeight, havePendingItems]);

  /**
   * Render starts here --------------------------------------------------------
   */

  return (
    <ScreenView
      header={screenHeader}
      keepAwake
      scrollView={false}
      paddingHorizontal={false}
      paddingVertical={false}
      loading={!sections || !props.shouldRender}
    >
      <GroceryList
        sections={sections}
        onItemStatusChange={props.onStatusChange}
        openEditSheet={props.openEditSheet}
        openRecipeOptionsSheet={props.openRecipeOptionsSheet}
        isRecipeExpanded={props.isRecipeExpanded}
        setIsRecipeExpanded={props.setIsRecipeExpanded}
        onPullToRefresh={props.onPullToRefresh}
        setSortBy={setSortBy}
        onScroll={groceryViewOnScrolls}
        scrollRefs={scrollRefs}
        showSwipeHint={props.showSwipeHint}
      />
      {!dimensions.isLargeScreen && <AddItemButton onPress={props.openAddSheet} />}
    </ScreenView>
  );
});

interface GroceryListProps {
  sections: SortedListSections;
  onItemStatusChange: GroceryItemStatusChangeHandler;
  openEditSheet: (id: GroceryListItemId) => void;
  openRecipeOptionsSheet: (recipeId: UserRecipeId, recipeInstanceId: RecipeInstanceId) => void;
  isRecipeExpanded: Set<RecipeInstanceId>;
  setIsRecipeExpanded: React.Dispatch<SetStateAction<Set<RecipeInstanceId>>>;
  onPullToRefresh: () => Promise<void>;
  setSortBy: (sortBy: GroceryListSort) => void;
  headerComponent?: ReactNode;
  onScroll: Record<GroceryListSort, ReturnType<typeof useAnimatedScrollHandler>>;
  scrollRefs: Record<GroceryListSort, ReturnType<typeof useAnimatedRef<any>>>;
  showSwipeHint: boolean;
}

const GroceryList = (props: GroceryListProps) => {
  const isEmptyState =
    props.sections.timeSorted.pending.length === 0 && props.sections.timeSorted.completed.length === 0;
  const isInitialState = !useCheckpointCompleted("gliCreated") && isEmptyState;

  return (
    <>
      {isInitialState && <InitialState />}
      {!isInitialState && (
        <>
          {isEmptyState && <EmptyState headline={strings.emptyState.headline} subhead={strings.emptyState.subhead} />}
          {!isEmptyState && (
            <>
              <GroceryListSortView
                ref={props.scrollRefs["category"]}
                hide={props.sections.activeSort !== "category"}
                ids={props.sections.categorySorted}
                onItemStatusChange={props.onItemStatusChange}
                onEdit={props.openEditSheet}
                onPullToRefresh={props.onPullToRefresh}
                onScroll={props.onScroll["category"]}
                showSwipeHint={props.sections.activeSort === "category" && props.showSwipeHint}
              />
              <GroceryListSortView
                ref={props.scrollRefs["time"]}
                hide={props.sections.activeSort !== "time"}
                ids={props.sections.timeSorted}
                onItemStatusChange={props.onItemStatusChange}
                onEdit={props.openEditSheet}
                onPullToRefresh={props.onPullToRefresh}
                onScroll={props.onScroll["time"]}
                showSwipeHint={props.sections.activeSort === "time" && props.showSwipeHint}
              />
              <GroceryListSortView
                ref={props.scrollRefs["recipe"]}
                hide={props.sections.activeSort !== "recipe"}
                ids={props.sections.recipeSorted}
                onItemStatusChange={props.onItemStatusChange}
                onEdit={props.openEditSheet}
                onPullToRefresh={props.onPullToRefresh}
                onScroll={props.onScroll["recipe"]}
                onPressRecipeOptions={props.openRecipeOptionsSheet}
                showSwipeHint={props.sections.activeSort === "recipe" && props.showSwipeHint}
              />
            </>
          )}
        </>
      )}
    </>
  );
};

const RecipeOptionsMenu = React.memo((props: { recipeInstanceId: RecipeInstanceId; recipeId: UserRecipeId }) => {
  const dispatch = useDispatch();
  const screen = useScreen();
  const bottomSheet = useBottomSheet();
  const listRecipe = useListRecipe(props.recipeInstanceId);

  const scale = listRecipe?.scale ?? 1;
  //SCALE-TODO: get from user settings
  const units: UnitConversion = "original";
  const recipeTitle = listRecipe?.title;
  const recipeYield = listRecipe?.recipeYield;

  const { navToScalingAndConversionsSheet } = useScalingAndConversionsSheet({
    recipeTitle,
    recipeYield,
    scale,
    unit: units,

    onChangeScale: (newScale: number) => {
      dispatch(groceryListRecipeScaleUpdatedClient({ recipeInstanceId: props.recipeInstanceId, scale: newScale }));
    },
    //SCALE-TODO-DISPATCH
    onChangeUnit: () => {},
  });

  const [waitingRemoveRecipe, setWaitingRemoveRecipe] = useState(false);

  const onRemoveRecipe = useCallback(async () => {
    Haptics.feedback("itemStatusChanged");

    setWaitingRemoveRecipe(true);
    await dispatch(deleteRecipeFromGroceryList(props.recipeInstanceId));
    setWaitingRemoveRecipe(false);

    Haptics.feedback("operationSucceeded");
    bottomSheet?.closeSheetAndGoBack();
  }, [bottomSheet, props.recipeInstanceId, dispatch, bottomSheet, setWaitingRemoveRecipe]);

  const onViewRecipe = useCallback(() => {
    bottomSheet?.closeSheetAndGoBack();
    setTimeout(
      () => screen.nav.switchTab("recipesTab", navTree.get.screens.recipeDetail, { recipeId: props.recipeId }),
      300
    );
  }, [bottomSheet, screen.nav.switchTab, props.recipeId]);

  const onPressScaleAndConvert = useCallback(() => {
    bottomSheet?.closeSheetAndGoBack();
    setTimeout(() => navToScalingAndConversionsSheet(), 300);
  }, [bottomSheet, navToScalingAndConversionsSheet]);

  return (
    <OptionsMenu>
      <OptionsMenuItem
        icon="scaleAndConvert"
        isFirst
        text={strings.recipeOptionsMenu.scaleRecipe}
        onPress={onPressScaleAndConvert}
      />
      <OptionsMenuItem icon="library" text={strings.recipeOptionsMenu.viewRecipe} onPress={onViewRecipe} />
      <OptionsMenuItem
        waiting={waitingRemoveRecipe}
        icon="delete"
        text={strings.recipeOptionsMenu.removeRecipe}
        onPress={onRemoveRecipe}
      />
    </OptionsMenu>
  );
});

const EmptyState = React.memo((props: { headline: string; subhead: string }) => {
  return (
    <View style={styles.emptyState}>
      <THeading2 align="center">{props.headline}</THeading2>
      <Spacer vertical={1} />
      <TBody align="center">{props.subhead}</TBody>
    </View>
  );
});

const InitialState = React.memo(() => {
  const screen = useScreen();
  const dispatch = useDispatch();

  const onPressLinkHousehold = useCallback(() => {
    const event = reportGroceryLinkYourHouseholdButtonTapped();
    dispatch(analyticsEvent(event));
    screen.nav.switchTab("profileTab", navTree.get.screens.householdManagement);
  }, [dispatch, screen.nav.switchTab]);

  return (
    <View style={styles.emptyState}>
      <THeading1 align="center" numberOfLines={1} adjustsFontSizeToFit>
        {strings.initialState.headline}
      </THeading1>
      <TBody align="center" lineHeight={28}>
        {strings.initialState.subhead}
      </TBody>
      <Spacer vertical={4} />
      <TBody align="center">
        <TBody
          onPress={onPressLinkHousehold}
          fontWeight="medium"
          color={globalStyleColors.colorTextLink}
          suppressHighlighting
        >
          {strings.initialState.linkYourHousehold[0]}
        </TBody>
        <TBody>{strings.initialState.linkYourHousehold[1]}</TBody>
      </TBody>
    </View>
  );
});

const styles = StyleSheet.create({
  emptyState: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    paddingHorizontal: 20,
    paddingBottom: 24,
  },
});
