import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { ScreenView } from "../components/ScreenView";
import { RecipeAddToGroceryScreenProps } from "../navigation/NavTree";
import { useScreen, withNonNavigableScreenContainer } from "../navigation/ScreenContainer";
import { useDispatch } from "../lib/redux/Redux";
import { addRecipeIngredientsToGroceryList } from "../lib/lists/ListsThunks";
import { GroceryListItemId } from "@eatbetter/lists-shared";
import { Haptics } from "../components/Haptics";
import { displayUnexpectedErrorAndLog } from "../lib/Errors";
import { HeaderProps } from "../components/ScreenHeaders";
import { useRecipe } from "../lib/recipes/RecipesSelectors";
import { TBody } from "../components/Typography";
import { Spacer } from "../components/Spacer";
import { Separator } from "../components/Separator";
import {
  getSelectableRecipeIngredients,
  RecipeIngredientSelectedHandler,
  RecipeIngredientsSelect,
  SelectableRecipeIngredients,
} from "../components/recipes/RecipeIngredientsSelect";
import { ScrollView } from "react-native-gesture-handler";
import { globalStyleConstants } from "../components/GlobalStyles";
import { BottomActionBar, bottomActionBarConstants } from "../components/BottomActionBar";
import { BottomNotch } from "../components/BottomNotch";
import { LayoutAnimation } from "react-native";
import { newId } from "@eatbetter/common-shared";
import { ScalingInlineControl } from "../components/recipes/ScalingAndConversions";
import { UnitConversion } from "@eatbetter/items-shared";
import { RecipeYieldDisplay } from "../components/recipes/RecipeYield";

const strings = {
  screenHeader: "Add to Grocery List",
  addToGrocery: (itemCount: number) => `Add ${itemCount} items`,
  noItemsSelected: "Select one or more items",
};

export const RecipeAddToGroceryScreen = withNonNavigableScreenContainer(
  "RecipeAddToGroceryScreen",
  (props: RecipeAddToGroceryScreenProps) => {
    const dispatch = useDispatch();
    const screen = useScreen();
    const newGroceryListItemId = useRef(newId<GroceryListItemId>());

    const recipe = useRecipe(props.recipeId);

    const [selectedIngredients, setSelectedIngredients] = useState<SelectableRecipeIngredients | undefined>(undefined);
    const [waitingAddToGrocery, setWaitingAddToGrocery] = useState(false);

    const [recipeScale, setRecipeScale] = useState(recipe?.scale ?? 1);
    //SCALE-TODO: get initial value from user settings
    const [recipeUnits, setRecipeUnits] = useState<UnitConversion>("original");

    const selectedCount = useMemo(() => {
      return (
        selectedIngredients?.sections.reduce((acc, curr) => {
          return acc + curr.items.filter(i => i.isSelected).length;
        }, 0) ?? 0
      );
    }, [selectedIngredients]);

    useEffect(() => {
      if (recipe && !selectedIngredients) {
        animateLayout();
        setSelectedIngredients(getSelectableRecipeIngredients(recipe.ingredients, { initialValue: true }));
      }
    }, [recipe]);

    const onSelectIngredient = useCallback<RecipeIngredientSelectedHandler>(
      (sectionId, ingredientIdx) => {
        Haptics.feedback("itemStatusChanged");
        animateLayout();
        setSelectedIngredients(prev => {
          if (!prev) {
            return prev;
          }
          return {
            ...prev,
            sections: prev.sections.map(section => {
              if (section.id === sectionId) {
                return {
                  ...section,
                  items: section.items.map((i, idx) => {
                    if (idx === ingredientIdx) {
                      return { ...i, isSelected: !i.isSelected };
                    }
                    return i;
                  }),
                };
              }
              return section;
            }),
          };
        });
      },
      [setSelectedIngredients]
    );

    const onPressAddToGrocery = useCallback(async () => {
      try {
        Haptics.feedback("itemStatusChanged");
        animateLayout();
        await dispatch(
          addRecipeIngredientsToGroceryList(
            props.recipeId,
            newGroceryListItemId.current,
            recipeScale,
            setWaitingAddToGrocery
          )
        );
        Haptics.feedback("operationSucceeded");
        screen.nav.goBack();
      } catch (err) {
        displayUnexpectedErrorAndLog("Add ingredients to grocery list failed", err, { recipeId: props.recipeId });
      }
    }, [dispatch, props.recipeId, newGroceryListItemId, setWaitingAddToGrocery, screen.nav.goBack, recipeScale]);

    const header = useMemo<HeaderProps>(() => {
      return {
        type: "default",
        title: strings.screenHeader,
        right: {
          type: "cancel",
          onPress: () => screen.nav.goBack(),
        },
      };
    }, [screen.nav.goBack]);

    return (
      <ScreenView
        header={header}
        isModal
        paddingHorizontal={false}
        paddingVertical={"headerAndBottomTabBar"}
        scrollView={false}
        loading={!recipe || !selectedIngredients}
      >
        {!!recipe && !!selectedIngredients && (
          <>
            <ScrollView
              contentContainerStyle={{
                paddingHorizontal: globalStyleConstants.defaultPadding,
                paddingBottom: bottomActionBarConstants.height + 5 * globalStyleConstants.unitSize,
              }}
            >
              <Spacer vertical={1.5} />
              <TBody fontWeight="medium" numberOfLines={1}>
                {recipe.title}
              </TBody>
              {!!recipe.recipeYield?.text && (
                <>
                  <Spacer vertical={0.25} />
                  <RecipeYieldDisplay
                    fontSize="body"
                    yield={recipe.recipeYield}
                    recipeScale={recipeScale}
                    italic
                    opacity="dark"
                  />
                </>
              )}
              <Spacer vertical={1} />
              <ScalingInlineControl
                recipeTitle={recipe.title}
                recipeYield={recipe.recipeYield}
                scale={recipeScale}
                onChangeScale={setRecipeScale}
                unit={recipeUnits}
                onChangeUnit={setRecipeUnits}
              />
              <Spacer vertical={1.5} />
              <Separator orientation="row" />
              <RecipeIngredientsSelect
                ingredients={selectedIngredients}
                onSelectIngredient={onSelectIngredient}
                recipeScale={recipeScale}
                recipeUnitConversion={recipeUnits}
              />
            </ScrollView>
            <BottomActionBar
              primaryAction={{
                actionIcon: selectedCount === 0 ? undefined : "grocery",
                actionText: selectedCount === 0 ? strings.noItemsSelected : strings.addToGrocery(selectedCount),
                onPressAction: onPressAddToGrocery,
                disabled: selectedCount === 0,
                waiting: waitingAddToGrocery,
                singlePress: true,
              }}
            />
            <BottomNotch />
          </>
        )}
      </ScreenView>
    );
  }
);

function animateLayout() {
  LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
}
