import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { AppDispatch, useDispatch } from "../redux/Redux";
import { debounce } from "lodash";

export interface AutoSaveTextEdit {
  text: string;
  setText: (s: string) => void;
  status: "initial" | "saved" | "pending" | "error";
}

export type PersistTextHandler = (
  text: string,
  appDispatch: AppDispatch,
  setPending: (v: boolean) => void,
  callSuccessful: (b: boolean) => void
) => void;

export const useAutoSaveTextEdit = (
  persistedText: string,
  setPersistedText: PersistTextHandler,
  opts?: { setPendingOnTextChange: boolean }
): AutoSaveTextEdit => {
  const [isInitialState, setIsInitialState] = useState(true);
  const [workingText, setWorkingText] = useState(persistedText);
  const isDirty = workingText !== persistedText;
  // keep track of errors so we can alert the user. We don't do any retries or anything fancy.
  // informational only, which is slightly better than failing silently, even with no recourse
  const [errorsSaving, setErrorsSaving] = useState(0);
  const [pending, setPending] = useState(false);
  const dispatch = useDispatch();

  const debounced = useRef(debounce(setPersistedText, 500, { trailing: true, leading: false })).current;

  // the goal here is to update the text the user sees if it changes independent of them,
  // for example, in the cooking session when another user has updated the recipe notes.
  // This is an extremly naive implementation and we'll want to get fancier if we see
  // users editing notes concurrently and use a 3-way merge or something like that.
  useEffect(() => {
    if (!isDirty) {
      setWorkingText(persistedText);
    }
  }, [persistedText, isDirty]);

  // flush calls when unmounting
  useEffect(() => {
    return debounced.flush;
  }, []);

  const setText = useCallback(
    (text: string) => {
      setWorkingText(text);

      if (opts?.setPendingOnTextChange) {
        setPending(true);
      }

      debounced(text, dispatch, setPending, (callSuccessful: boolean) => {
        if (callSuccessful) {
          setErrorsSaving(0);
        } else {
          setErrorsSaving(curr => ++curr);
        }
        setIsInitialState(false);
      });
    },
    [debounced, setPending, setWorkingText, setErrorsSaving, setIsInitialState, opts?.setPendingOnTextChange, dispatch]
  );

  return useMemo(() => {
    // note we don't currently have a status for "waiting" where isDirty is true
    // but the call to update hasn't started yet.
    const status = errorsSaving ? "error" : pending ? "pending" : isInitialState ? "initial" : "saved";
    return { text: workingText, setText, status };
  }, [workingText, setText, errorsSaving, pending, isInitialState]);
};
