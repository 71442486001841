import { GroceryListItemId } from "@eatbetter/lists-shared";
import { PhotoRef } from "@eatbetter/photos-shared";
import React, { useCallback, useMemo } from "react";
import { Platform, View, StyleSheet } from "react-native";
import { useListItem, useListRecipe, useListRecipeColor } from "../../lib/lists/ListsSelectors";
import { useRecipePhoto } from "../../lib/recipes/RecipesSelectors";
import { useAuthedUser } from "../../lib/system/SystemSelectors";
import { Photo } from "../Photo";
import { SwipeableRow } from "../SwipeableRow";
import { Text, TextProps } from "../Typography";
import { globalStyleColors, globalStyleConstants } from "../GlobalStyles";
import { TouchableWithoutFeedback } from "react-native-gesture-handler";
import { UserId } from "@eatbetter/common-shared";
import { useScaled } from "../../lib/recipes/UseScaled";
import { UnitConversion } from "@eatbetter/items-shared";
import { ModifiableRecipeText } from "../recipes/RecipeText";

const config = {
  itemCardMinHeight: 58,
};

export const groceryListItemConstants = {
  marginTop: globalStyleConstants.minPadding,
  minHeight: config.itemCardMinHeight,
};

export type GroceryItemStatusChangeHandler = (
  id: GroceryListItemId,
  status: "pending" | "completed",
  groupCount?: number,
  groupSwipe?: boolean
) => void;

interface GroceryListItemProps {
  id: GroceryListItemId;
  onStatusChange: GroceryItemStatusChangeHandler;
  onEdit?: (id: GroceryListItemId) => void;
  indentContent?: number;
  groupCount?: number;
  disabled?: boolean;
  showSwipeHint: boolean;
}

export const GroceryListItem = React.memo((props: GroceryListItemProps) => {
  const item = useListItem(props.id);

  const recipeItem = item.type === "recipe" ? item : undefined;
  const recipeIngredient = recipeItem ? { text: recipeItem.text, scaling: recipeItem.scaling } : undefined;
  const listRecipe = useListRecipe(recipeItem?.recipeInstanceId);
  const recipeColor = useListRecipeColor(recipeItem?.recipeId);
  const recipePhoto = useRecipePhoto(recipeItem?.recipeId);

  //SCALE-TODO: get from user settings
  const units: UnitConversion = "original";
  const scaledText = useScaled(recipeIngredient, listRecipe?.scale ?? 1, units);

  const text = scaledText.length > 0 ? scaledText : item.text;
  const textProps: Pick<TextProps, "opacity" | "strikethrough" | "numberOfLines"> & { fontSize: "secondary" } = {
    fontSize: "secondary",
    opacity: item.status.status === "completed" ? "medium" : undefined,
    strikethrough: item.status.status === "completed",
    numberOfLines: 4,
  };

  const onStatusChange = useCallback(() => {
    props.onStatusChange(props.id, item.status.status === "pending" ? "completed" : "pending", props.groupCount);
  }, [props.onStatusChange, props.id, props.groupCount, item.status.status]);

  const onEdit = useCallback(() => {
    props.onEdit?.(props.id);
  }, [props.onEdit, props.id]);

  return (
    <SwipeableRow
      onSwipedLeft={onStatusChange}
      onSwipedRight={onStatusChange}
      actionStyle={item.status.status === "pending" ? "completeAction" : "undoAction"}
      minHeight={config.itemCardMinHeight}
      disabled={props.disabled}
      showSwipeHint={props.showSwipeHint}
    >
      {/* React-Native Pressable did not play nicely with the react-native-gesture-handler swipeable component*/}
      <TouchableWithoutFeedback
        onPress={Platform.OS !== "web" ? onEdit : undefined}
        onLongPress={Platform.OS === "web" ? onEdit : undefined}
        delayLongPress={500}
        disabled={!props.onEdit}
      >
        <View style={styles.itemCard}>
          <View style={[styles.itemCardContentLeft, { paddingLeft: props.indentContent }]}>
            {typeof text === "string" && <Text {...textProps}>{text}</Text>}
            {typeof text !== "string" && <ModifiableRecipeText {...textProps} tokens={text} />}
          </View>
          <View style={styles.itemCardContentRight}>
            {item.type === "manual" && <AddedByAvatar addedBy={item.addedBy} />}
            {item.type === "recipe" && (
              <View>
                <Photo style="thumbnailXsmall" source={recipePhoto} placeholderColor={recipeColor} sourceSize="w288" />
              </View>
            )}
          </View>
        </View>
      </TouchableWithoutFeedback>
    </SwipeableRow>
  );
});

const AddedByAvatar = React.memo((props: { addedBy: UserId }) => {
  const authedUser = useAuthedUser();

  const avatarImage = useMemo((): PhotoRef | undefined => {
    if (authedUser?.household) {
      if (props.addedBy !== authedUser.userId) {
        return authedUser.household.find(u => u.userId === props.addedBy)?.photo;
      }
    }

    return undefined;
  }, [authedUser, props.addedBy]);
  return <>{!!avatarImage && <Photo style="avatarXsmall" source={avatarImage} sourceSize="w288" />}</>;
});

const itemShadow = {
  shadowColor: globalStyleColors.black,
  shadowOpacity: 0.1,
  shadowRadius: 12,
  shadowOffset: { width: 0, height: 4 },
};

const styles = StyleSheet.create({
  itemCard: {
    flexDirection: "row",
    justifyContent: "space-between",
    backgroundColor: globalStyleColors.white,
    borderRadius: 20,
    paddingRight: 20,
    paddingLeft: 20,
    minHeight: config.itemCardMinHeight,
    ...itemShadow,
    shadowRadius: 8,
  },
  itemCardContentLeft: {
    flexDirection: "column",
    flexShrink: 1,
    justifyContent: "space-around",
    paddingRight: globalStyleConstants.unitSize,
    paddingVertical: globalStyleConstants.unitSize,
  },
  itemCardContentRight: {
    flexDirection: "column",
    justifyContent: "space-around",
  },
});
