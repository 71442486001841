import React, { PropsWithChildren } from "react";
import { globalStyleColors, Opacity } from "../GlobalStyles";
import { Text, TextProps } from "../Typography";
import { ModifiedText } from "../../lib/recipes/UseScaled";

export interface ModifiableRecipeTextProps {
  tokens: ModifiedText[];
  fontSize: Extract<TextProps["fontSize"], "body" | "secondary">;
  fontScale?: number;
  opacity?: keyof typeof Opacity;
  strikethrough?: boolean;
  numberOfLines?: number;
  italic?: boolean;
}

export const ModifiableRecipeText = React.memo((props: ModifiableRecipeTextProps) => {
  return (
    <RecipeText
      fontSize={props.fontSize}
      fontScale={props.fontScale}
      opacity={props.opacity}
      strikethrough={props.strikethrough}
    >
      {props.tokens.map((i, idx) => {
        const getRecipeTextProps = (): RecipeTextProps => {
          const modifiedTextProps: Partial<RecipeTextProps> = i.modified
            ? {
                fontWeight: "medium",
                color: globalStyleColors.rgba("colorAccentCool", "dark"),
                underline: "double",
                underlineColor: globalStyleColors.rgba("colorAccentCool", "light"),
                italic: props.italic,
              }
            : {};

          return {
            ...modifiedTextProps,
            fontSize: props.fontSize,
            // Not inherited down the tree since our fonts use substitution instead of modification to implement italics
            italic: props.italic,
          };
        };

        return (
          <RecipeText key={idx} {...getRecipeTextProps()}>
            {i.text}
          </RecipeText>
        );
      })}
    </RecipeText>
  );
});

interface RecipeTextProps {
  fontSize: Extract<TextProps["fontSize"], "body" | "secondary">;
  fontScale?: number;
  fontWeight?: TextProps["fontWeight"];
  color?: string;
  opacity?: keyof typeof Opacity;
  strikethrough?: boolean;
  underline?: TextProps["underline"];
  underlineColor?: string;
  numberOfLines?: number;
  italic?: boolean;
}

export const RecipeText = React.memo((props: PropsWithChildren<RecipeTextProps>) => {
  return (
    <Text
      color={props.color}
      fontSize={props.fontSize}
      fontWeight={props.fontWeight}
      scale={props.fontScale}
      enableFontScaling={props.fontScale ? "upOnly" : undefined}
      opacity={props.opacity}
      strikethrough={props.strikethrough}
      underline={props.underline}
      underlineColor={props.underlineColor}
      numberOfLines={props.numberOfLines}
      italic={props.italic}
    >
      {props.children}
    </Text>
  );
});
