import { useMemo } from "react";
import { bottomNop } from "@eatbetter/common-shared";
import { Measurement, QuantityUnit, ScalingAndConversionInfo, UnitConversion } from "@eatbetter/items-shared";

export interface ModifiedText {
  modified: boolean;
  text: string;
}

export interface ScalableText {
  text: string;
  scaling?: ScalingAndConversionInfo;
}

export const useScaled = (i: ScalableText | undefined, scale: number, conversion: UnitConversion): ModifiedText[] => {
  return useMemo(() => {
    if (!i) {
      return [];
    }
    return scaleRecipeText(i, scale, conversion);
  }, [i, scale, conversion]);
};

interface ChangedString {
  indices: [number, number];
  text: string;
}

export function scaleRecipeText(i: ScalableText, scale: number, conversion: UnitConversion): Array<ModifiedText> {
  const qu = (i.scaling?.measurements ?? []).filter(s => s.scale);
  if (scale === 1 || qu.length === 0) {
    return [{ text: i.text, modified: false }];
  }

  const changes = qu.flatMap(m => getChangedValues(m, scale, conversion));

  const mt: ModifiedText[] = [];
  const text = i.text;

  let currentIndex = 0;
  changes.forEach(change => {
    const before = text.substring(currentIndex, change.indices[0]);
    if (before.length > 0) {
      mt.push({ modified: false, text: before });
    }
    mt.push({ modified: true, text: change.text });
    currentIndex = change.indices[1] + 1;
  });

  const after = text.substring(currentIndex);
  if (after.length > 0) {
    mt.push({ modified: false, text: after });
  }

  return mt;
}

function getChangedValues(m: Measurement, scale: number, _conversion: UnitConversion): Array<ChangedString> {
  switch (m.type) {
    case "simple":
      return getChangedValuesFromQuantityUnit(m, scale, _conversion);
    case "additive":
    case "alternates":
      return [];
    default:
      bottomNop(m);
  }

  throw new Error("This should never be hit");
}

function getChangedValuesFromQuantityUnit(
  qu: QuantityUnit,
  scale: number,
  _conversion: UnitConversion
): Array<ChangedString> {
  const qs = Array.isArray(qu.q) ? qu.q : [qu.q];
  return qs.map(q => {
    const newValue = q.value * scale;
    const text = Number.isInteger(newValue) ? newValue.toString() : newValue.toFixed(1);
    return { text, indices: q.idx };
  });
}
