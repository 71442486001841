import { SyncThunkAction, ThunkAction } from "../redux/Redux";
import {
  groceryListsErrored,
  groceryListsReceived,
  groceryListsRequested,
  groceryListItemUpdateStarted,
  groceryListItemPersistStarted,
  groceryListItemPersistSuccess,
  groceryListItemPersistErrored,
  groceryListItemUpdateSuccess,
  groceryListItemUpdateErrored,
  groceryListItemsConflict,
  groceryListItemStatusUpdated,
  groceryListSuggestionsErrored,
  groceryListSuggestionsRequested,
  groceryListSugggestionsReceived,
  groceryListItemAddedFromSuggestion,
  groceryListItemManualCategoryUpdated,
  NewGroceryListItem,
  groceryListItemAdded,
  spotlightGroceryIconChanged,
  groceryListRecipeUpdateStarted,
  groceryListRecipeUpdateSuccess,
  groceryListRecipeUpdateErrored,
} from "./ListsSlice";
import { log } from "../../Log";
import {
  GroceryListId,
  GroceryListItemId,
  GroceryListItemStatus,
  GroceryListSuggestion,
  RecipeInstanceId,
} from "@eatbetter/lists-shared";
import { StructuredError, UserId } from "@eatbetter/common-shared";
import { isLoading } from "../redux/ServerData";
import { GroceryListAutocompleteResults, StandardPrimaryCategory } from "@eatbetter/items-shared";
import { UserRecipeId } from "@eatbetter/recipes-shared";
import { selectItemAndUpdates, selectMergedListItems } from "./ListsSelectors";
import {
  reportGroceryListCategoryChanged,
  reportGroceryListItemAdded,
  reportGroceryListItemEdited,
  reportGroceryListItemStatusChange,
  reportGroceryListSuggestionTapped,
  reportRecipeAddedToGroceryList,
} from "../analytics/AnalyticsEvents";
import { analyticsEvent } from "../analytics/AnalyticsThunks";
import { mergeItemWithUpdates, mergeUpdates } from "../redux/ItemWithUpdates";
import { selectCheckpointCompleted } from "../system/SystemSelectors";
import { checkpointsCompleted } from "../system/SystemSlice";
import { maybePromptForReview } from "../system/SystemThunks";

export const loadGroceryLists = (): ThunkAction<void> => {
  return async (dispatch, getState, deps) => {
    try {
      log.info("Thunk: loadGroceryLists");
      if (isLoading("groceryLists.meta", getState(), s => s.groceryLists.meta)) {
        log.info("loadGroceryLists called, but status is already 'loading'");
        return;
      }

      const startTime = deps.time.epochMs();
      dispatch(groceryListsRequested(startTime));

      const resp = await deps.api.withThrow().getGroceryLists();
      dispatch(groceryListsReceived({ startTime, data: resp.data }));
    } catch (err) {
      log.errorCaught("Unexpected error fetching grocery lists", err);
      dispatch(groceryListsErrored());
    }
  };
};

export const loadGroceryListSuggestions = (): ThunkAction<void> => {
  return async (dispatch, _getState, deps) => {
    log.info("Thunk: loadGroceryListSuggestions");

    try {
      const startTime = deps.time.epochMs();
      dispatch(groceryListSuggestionsRequested(startTime));

      const resp = await deps.api.withThrow().getGroceryListSuggestions();
      dispatch(groceryListSugggestionsReceived({ startTime, data: resp.data }));
    } catch (err) {
      log.errorCaught("Unexpected error fetching grocery list suggestions", err);
      dispatch(groceryListSuggestionsErrored());
    }
  };
};

export const persistGroceryListItem = (listId: GroceryListId, itemId: GroceryListItemId): ThunkAction<void> => {
  return async (dispatch, gs, deps) => {
    log.info("Thunk: persistGroceryListItem", { listId, itemId });
    let started = false;

    try {
      const state = gs();
      const itemAndUpdates = selectItemAndUpdates(state, itemId);

      if (!itemAndUpdates) {
        log.error("persistGroceryListItem called but itemAndUpdates is undefined", { listId, itemId });
        return;
      }

      const { item, itemState } = itemAndUpdates;

      if (item.type !== "manual") {
        log.error(`persistGrocerListItem called but itemAndUpdates.item.type is ${item.type}`, { itemAndUpdates });
        return;
      }

      log.info("persistGroceryListItem itemAndUpdates", { itemAndUpdates });

      if (itemState !== "createNeeded") {
        log.error(`persistGroceryListItem called when item state is ${itemState}`);
        return;
      }

      started = true;
      dispatch(groceryListItemPersistStarted({ id: itemId }));
      const resp = await deps.api.withReturn().addGroceryListItem({
        listId,
        itemId,
        suggestionId: item.suggestionId,
        manualCategory: item.manualCategory,
        text: item.text,
        ts: item.created,
      });

      if (resp.data) {
        log.info("persistGroceryListItem success");
        dispatch(groceryListItemPersistSuccess({ versions: resp.data }));

        // we report the creation in the persist thunk instead of when the item
        // is actually added so we can include the category.
        const d = resp.data[itemId];
        const event = reportGroceryListItemAdded({
          itemId,
          listId,
          text: item.text,
          category: d?.category,
          manualCategory: d?.manualCategory,
          fromSuggestion: !!item.suggestionId,
        });
        dispatch(analyticsEvent(event));

        return;
      }

      if (resp.error && resp.error.code === "lists/groceryListItemConflict") {
        log.info("persistGroceryListItem conflict error");
        dispatch(groceryListItemsConflict({ items: resp.error.payload.items }));
        return;
      }

      log.error("persistGroceryListItem resulted in unexpected error. Throwing.", { error: resp.error });
      throw new StructuredError(resp.error);
    } catch (err) {
      log.errorCaught(`Unexpected error persisting grocery list item ${itemId}`, err);

      if (started) {
        dispatch(groceryListItemPersistErrored({ id: itemId }));
      }
    }
  };
};

export const groceryListItemStatusUpdatedClient = (args: {
  id: GroceryListItemId;
  status: GroceryListItemStatus["status"];
  groupCount?: number;
  groupSwipe?: boolean;
}): SyncThunkAction<void> => {
  return (dispatch, getState, _deps) => {
    log.info("Thunk: groceryListItemStatusUpdatedClient", { args });
    const beforeState = getState();

    if (!selectCheckpointCompleted(beforeState, "gliSwiped")) {
      dispatch(checkpointsCompleted(["gliSwiped"]));
    }

    // this needs to come after setting the gliSwiped checkpoint
    // we were seeing double hints when this was above, even though I was under the impression
    // the state updates would be batched in React 18. I'm wondering if our app is still
    // running in react 17 mode - https://reactnative.dev/docs/react-18-and-react-native
    dispatch(groceryListItemStatusUpdated(args));

    const afterState = getState();
    const itemAndUpdates = afterState.groceryLists.items.entities[args.id];

    if (itemAndUpdates) {
      const item = mergeItemWithUpdates(itemAndUpdates);
      const view = afterState.groceryLists.sort;
      const event = reportGroceryListItemStatusChange({
        item,
        view,
        groupCount: args.groupCount,
        groupSwipe: args.groupSwipe,
      });
      dispatch(analyticsEvent(event));
    }
  };
};

export const groceryListItemAddedClient = (item: Omit<NewGroceryListItem, "id">): SyncThunkAction<void> => {
  return (dispatch, getState, _deps) => {
    dispatch(groceryListItemAdded(item));

    if (!selectCheckpointCompleted(getState(), "gliCreated")) {
      dispatch(checkpointsCompleted(["gliCreated"]));
    }
  };
};

export const groceryListSuggestionTapped = (args: {
  addedBy: UserId;
  suggestion: GroceryListSuggestion;
  type: "Pill" | "Typeahead";
  index: number;
}): SyncThunkAction<void> => {
  return (dispatch, getState, _deps) => {
    log.info("Thunk: groceryListSuggestionTapped");
    dispatch(groceryListItemAddedFromSuggestion({ addedBy: args.addedBy, suggestion: args.suggestion }));

    if (!selectCheckpointCompleted(getState(), "gliCreated")) {
      dispatch(checkpointsCompleted(["gliCreated"]));
    }

    const event = reportGroceryListSuggestionTapped({
      type: args.type,
      suggestion: args.suggestion,
      index: args.index,
    });
    dispatch(analyticsEvent(event));
  };
};

export const groceryListItemCategoryChanged = (args: {
  id: GroceryListItemId;
  manualCategory: StandardPrimaryCategory;
}): SyncThunkAction<void> => {
  return (dispatch, getState, _deps) => {
    dispatch(groceryListItemManualCategoryUpdated({ id: args.id, manualCategory: args.manualCategory }));

    const state = getState();
    const item = state.groceryLists.items.entities[args.id];
    const listId = state.groceryLists.selectedListId;

    if (!item) {
      log.warn(`Item ID ${args.id} not found in groceryilstItemCategoryChanged`);
      return;
    }

    const merged = mergeItemWithUpdates(item);
    const event = reportGroceryListCategoryChanged({ item: merged, listId });
    dispatch(analyticsEvent(event));
  };
};

export const updateGroceryListRecipe = (
  listId: GroceryListId,
  recipeInstanceId: RecipeInstanceId
): ThunkAction<void> => {
  return async (dispatch, gs, deps) => {
    log.info("Thunk: updateGroceryListRecipe", { listId, recipeInstanceId });
    let started = false;

    try {
      const state = gs();
      const list = state.groceryLists.lists.entities[listId];
      const itemAndUpdates = list?.recipeInstances[recipeInstanceId];

      if (!itemAndUpdates) {
        log.warn("updateGroceryListRecipe couldn't find recipe", { listId, recipeInstanceId });
        return;
      }

      log.info("updateGroceryListRecipe itemAndUpdates", { itemAndUpdates });

      if (itemAndUpdates.itemState !== "updateNeeded" || itemAndUpdates.updates.filter(u => u.pending).length > 0) {
        log.error("updateGroceryListREcipe called when update is already in progress", { itemAndUpdates });
        return;
      }

      if (itemAndUpdates.updates.length > 0) {
        const updates = mergeUpdates(itemAndUpdates.updates);
        started = true;

        dispatch(groceryListRecipeUpdateStarted({ listId, recipeInstanceId }));
        log.info("updateGroceryRecipe calling API", { updates });
        const resp = await deps.api.withReturn().editGroceryListRecipe({
          listId,
          recipeInstanceId,
          listRecipeVersion: itemAndUpdates.item.version,
          ...updates,
        });

        if (resp.data) {
          log.info("updateGroceryListRecipe success");
          dispatch(groceryListRecipeUpdateSuccess({ listId, recipeInstance: resp.data }));
          return;
        }

        if (resp.error && resp.error.code === "lists/groceryListItemConflict") {
          log.info("updateGroceryListRecipe resulted in conflict. Refreshing list");
          await dispatch(loadGroceryLists());
          return;
        }

        log.error("updateGroceryListRecipe resulted in unexpected error. Throwing.", { error: resp.error });
        throw new StructuredError(resp.error);
      }
    } catch (err) {
      log.errorCaught(`Unexpected error updating grocery recipe ${listId} ${recipeInstanceId}`, err);

      if (started) {
        dispatch(groceryListRecipeUpdateErrored({ listId, recipeInstanceId }));
      }
    }
  };
};

export const updateGroceryListItem = (listId: GroceryListId, itemId: GroceryListItemId): ThunkAction<void> => {
  return async (dispatch, gs, deps) => {
    log.info("Thunk: updateGroceryListItem", { listId, itemId });
    let started = false;

    try {
      const state = gs();
      const itemAndUpdates = selectItemAndUpdates(state, itemId);

      if (!itemAndUpdates) {
        log.error("updateGroceryListItem called but itemAndUpdates is undefined", { listId, itemId });
        return;
      }

      log.info("updateGroceryListItem itemAndUpdates", { itemAndUpdates });

      if (itemAndUpdates.itemState !== "updateNeeded" || itemAndUpdates.updates.filter(u => u.pending).length > 0) {
        log.error("updateGroceryListItem called when update is already in progress", { itemAndUpdates });
        return;
      }

      if (itemAndUpdates.updates.length > 0) {
        const updates = mergeUpdates(itemAndUpdates.updates);
        started = true;
        dispatch(groceryListItemUpdateStarted({ id: itemId }));
        log.info("updateGroceryListItem calling API", { updates });
        const resp = await deps.api.withReturn().updateGroceryListItem({
          listId,
          itemId,
          version: itemAndUpdates.item.version,
          updates,
        });

        if (resp.data) {
          log.info("updateGroceryListItem success");
          dispatch(groceryListItemUpdateSuccess({ updatedItem: resp.data }));

          if (updates.text) {
            const event = reportGroceryListItemEdited({
              oldText: itemAndUpdates.item.text,
              newText: updates.text,
              listId,
              itemId,
              category: resp.data.category,
            });

            dispatch(analyticsEvent(event));
          }

          return;
        }

        if (resp.error && resp.error.code === "lists/groceryListItemConflict") {
          log.info("updateGroceryListItem resulted in conflict");
          dispatch(groceryListItemsConflict({ items: resp.error.payload.items }));
          return;
        }

        log.error("updateGroceryListItem resulted in unexpected error. Throwing.", { error: resp.error });
        throw new StructuredError(resp.error);
      }
    } catch (err) {
      log.errorCaught(`Unexpected error updating grocery list item ${itemId}`, err);

      if (started) {
        dispatch(groceryListItemUpdateErrored({ id: itemId }));
      }
    }
  };
};

export const getGroceryItemSuggestions = (q: string): ThunkAction<GroceryListAutocompleteResults> => {
  return async (_dispatch, _gs, deps) => {
    try {
      const resp = await deps.api.withThrow().getGroceryItemSuggestions({ q });
      return resp.data;
    } catch (err) {
      log.errorCaught(`Unexpected error fetching grocery item suggestions for ${q}`, err);
      return {} as GroceryListAutocompleteResults;
    }
  };
};

export const addRecipeIngredientsToGroceryList = (
  recipeId: UserRecipeId,
  itemId: GroceryListItemId,
  scale: number,
  waitingHandler?: (waiting: boolean) => void
): ThunkAction<void> => {
  return async (dispatch, getState, deps) => {
    log.info("Thunk: addRecipeIngredientsToGroceryList", { recipeId, itemId });

    try {
      waitingHandler?.(true);
      const state = getState();
      const listId = state.groceryLists.selectedListId!;

      // get the recipe before so we know that the added to list count has not
      // yet been incremented. We want this to appear as zero on the first event.
      const recipe = state.recipes.entities[recipeId];

      const resp = await deps.api.withReturn().addRecipeIngredientsToGroceryList({
        itemId,
        recipeId,
        listId,
        scale,
      });

      // if it's a conflict, we've already added the items to the list - ignore
      // (or there is a bug with the idempotent ID)
      if (resp.error && resp.error.code !== "lists/groceryListItemConflict") {
        throw new StructuredError(resp.error);
      }

      // reload the grocery lists since there are new items
      // we don't wait for this since it might arrive via websocket as well, the user isn't on the screen anyway
      dispatch(loadGroceryLists()).catch(err => {
        log.errorCaught("Error reloading grocery lists in addRecipeIngredientsToGroceryList", err);
      });

      if (!selectCheckpointCompleted(state, "gliCreated")) {
        dispatch(checkpointsCompleted(["gliCreated"]));
      }

      if (!selectCheckpointCompleted(state, "recipeAddedToGrocery")) {
        dispatch(checkpointsCompleted(["recipeAddedToGrocery"]));
        dispatch(spotlightGroceryIconChanged(true));
      }

      const event = reportRecipeAddedToGroceryList({
        recipe,
        listId,
        itemId,
      });

      dispatch(analyticsEvent(event));

      // add a small delay here so the haptics/success of the add action complete
      setTimeout(() => {
        dispatch(maybePromptForReview("Recipe Added to Grocery List"));
      }, 500);
    } finally {
      waitingHandler?.(false);
    }
  };
};

export const deleteRecipeFromGroceryList = (recipeInstanceId: RecipeInstanceId): ThunkAction<void> => {
  return async (dispatch, getState, deps) => {
    log.info("Thunk: deleteRecipeFromGroceryList", { recipeInstanceId });
    const listId = getState().groceryLists.selectedListId!;
    await deps.api.withThrow().deleteRecipeIngredientsFromGroceryList({ listId, recipeInstanceId });
    await dispatch(loadGroceryLists());
  };
};

export const completeAllGroceryListItems = (): SyncThunkAction<void> => {
  return (dispatch, getState, _deps) => {
    log.info("Thunk: completeAllGroceryListItems");
    const state = getState();

    const items = selectMergedListItems(state);
    items
      .filter(i => i.status.status === "pending")
      .forEach(i => {
        dispatch(groceryListItemStatusUpdated({ id: i.id, status: "completed" }));
      });
  };
};
