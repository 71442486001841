import React from "react";
import { View } from "react-native";
import { TBody, TSecondary } from "./Typography";

export const SectionHeading = React.memo(
  (props: { prefix?: string; text: string; isActionable?: boolean; noPadding?: boolean }) => {
    const padding = props.noPadding ? {} : { paddingHorizontal: 20 };

    return (
      <View style={[{ flexShrink: 1 }, padding]}>
        {props.isActionable !== undefined ? (
          <TBody opacity={props.isActionable ? "opaque" : "medium"} strikethrough={props.isActionable === false}>
            {!!props.prefix && (
              <TBody opacity="medium" italic>
                <TBody>{"("}</TBody>
                <TBody>{props.prefix}</TBody>
                <TBody>{")"}</TBody>
                <TBody> </TBody>
              </TBody>
            )}
            {props.text}
          </TBody>
        ) : (
          <TSecondary>{props.text.toUpperCase()}</TSecondary>
        )}
      </View>
    );
  }
);
