import { useScreen, withScreenContainer } from "../navigation/ScreenContainer";
import { ScreenView } from "../components/ScreenView";
import { useCallback, useEffect, useState } from "react";
import {
  processPhotosFromLibrary,
  selectPhotoFromCamera,
  selectPhotosFromLibrary,
  showCameraPermissionAlertAndNavToSettings,
} from "../lib/photos/SelectPhoto";
import { AppAddPhotoArgs } from "../lib/Types";
import { TSecondary } from "../components/Typography";
import { Spacer } from "../components/Spacer";
import { Image, LayoutAnimation, ScrollView, StyleSheet, View } from "react-native";
import { FlexedSpinner, Spinner } from "../components/Spinner";
import React from "react";
import { IconCamera, IconCircleCheck, IconEx } from "../components/Icons";
import { Pressable } from "../components/Pressable";
import { useDispatch } from "../lib/redux/Redux";
import { addRecipeFromPhotos } from "../lib/recipes/RecipesThunks";
import { displayUnexpectedErrorAndLog } from "../lib/Errors";
import { globalStyleColors, globalStyleConstants, globalStyles } from "../components/GlobalStyles";
import { bottomThrow, range } from "@eatbetter/common-shared";
import { smallScreenBreakpoint } from "../components/Responsive";
import { Haptics } from "../components/Haptics";
import { BottomActionBar, bottomActionBarConstants } from "../components/BottomActionBar";
import { Paragraph, ParagraphComponent } from "../components/LongformText";
import { Separator } from "../components/Separator";
import { navTree } from "../navigation/NavTree";
import { ContainerFlexCentered } from "../components/Containers";
import { getOptionsMenuHeight, OptionsMenu, OptionsMenuItem } from "../components/OptionsMenu";
import { useBottomSheet } from "./BottomSheetScreen";
import { ShimmerEffect } from "../components/AttentionGrabbers";

const strings = {
  screenTitle: "Add a recipe from photos",
  addPhotos: "Add Photos",
  takePhoto: "Take a Photo",
  selectPhotos: "Select Photos from Library",
  photosSelected: (count: number) =>
    count === 0 ? "Add up to 3 photos of the same recipe" : `${count} photo${count === 1 ? "" : "s"} selected`,
  submit: "Create Recipe",
  tips: "Tips",
  tipsBullets: [
    {
      type: "text",
      text: "Add one photo per page (3 max).",
    },
    {
      type: "text",
      text: "Avoid shadows.",
    },
    {
      type: "text",
      text: "Flatten the page as much as possible.",
    },
    {
      type: "text",
      text: "Frame each page tightly and ensure all the text is visible.",
    },
  ] satisfies Paragraph["fragments"],
};

function layoutAnimation() {
  LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
}

const maxPhotos = 3;

export const RecipeAddFromPhotosScreen = withScreenContainer("RecipeAddFromPhotosScreen", () => {
  const [selectedPhotos, setSelectedPhotos] = useState<AppAddPhotoArgs[]>([]);
  const [waitingSelectPhoto, setWaitingSelectPhoto] = useState<"camera" | "library" | null>(null);
  const [processingPhotos, setProcessingPhotos] = useState(0);
  const [uploadProgress, setUploadProgress] = useState<Record<string, number>>({});
  const [waitingSubmit, setWaitingSubmit] = useState(false);
  const dispatch = useDispatch();
  const { nav } = useScreen();

  const selectFromLibrary = useCallback(async () => {
    if (selectedPhotos.length >= maxPhotos) {
      return;
    }

    Haptics.feedback("itemStatusChanged");
    setWaitingSelectPhoto("library");
    const selected = await selectPhotosFromLibrary(maxPhotos - selectedPhotos.length, { ordered: true });
    if (selected.success) {
      setWaitingSelectPhoto(null);
      setProcessingPhotos(selected.result.length);
      const processed = await processPhotosFromLibrary(selected.result);
      if (processed.success) {
        setSelectedPhotos(cur => [...cur, ...processed.result]);
      }
      setProcessingPhotos(0);
    }
    setWaitingSelectPhoto(null);
  }, [setWaitingSelectPhoto, setProcessingPhotos, setSelectedPhotos, selectedPhotos]);

  const takePhoto = useCallback(async () => {
    if (selectedPhotos.length >= maxPhotos) {
      return;
    }

    Haptics.feedback("itemStatusChanged");
    setWaitingSelectPhoto("camera");
    const res = await selectPhotoFromCamera();
    if (res.success) {
      setSelectedPhotos(cur => [...cur, res.result]);
    }
    setWaitingSelectPhoto(null);

    if (!res.success && res.result.reason === "accessDenied") {
      showCameraPermissionAlertAndNavToSettings();
    }
  }, [setWaitingSelectPhoto, setSelectedPhotos, selectedPhotos]);

  const selectAddPhotoMethod = useCallback(() => {
    nav.modal(navTree.get.screens.bottomSheet, {
      content: <AddPhotoOptionsSheet takePhoto={takePhoto} selectFromLibrary={selectFromLibrary} />,
      height: getOptionsMenuHeight(2),
    });
  }, [nav.modal, takePhoto, selectFromLibrary]);

  const onUnselect = useCallback(
    (photo: AppAddPhotoArgs) => {
      Haptics.feedback("itemStatusChanged");
      layoutAnimation();
      setSelectedPhotos(cur => cur.filter(p => p.uri !== photo.uri));
    },
    [setSelectedPhotos]
  );

  const createRecipe = useCallback(async () => {
    try {
      const onProgress = (uri: string, progress: number) => {
        setUploadProgress(cur => ({ ...cur, [uri]: progress }));
      };

      Haptics.feedback("itemStatusChanged");
      await dispatch(addRecipeFromPhotos(selectedPhotos, onProgress, setWaitingSubmit));
      if (nav.canGoBack()) {
        nav.goBack();
      }
      Haptics.feedback("operationSucceeded");
    } catch (err) {
      displayUnexpectedErrorAndLog("Error dispatching addRecipeFromPhotos", err);
    }
  }, [selectedPhotos, setUploadProgress, setWaitingSubmit]);

  return (
    <ScreenView
      header={{ type: "default", title: strings.screenTitle }}
      scrollView={false}
      paddingHorizontal="none"
      paddingVertical="headerAndBottomTabBar"
    >
      <ScrollView
        contentContainerStyle={{
          paddingHorizontal: globalStyleConstants.defaultPadding,
          paddingBottom: bottomActionBarConstants.height + 3 * globalStyleConstants.unitSize,
        }}
      >
        <View style={{ width: "100%", alignSelf: "center", maxWidth: smallScreenBreakpoint }}>
          <Spacer vertical={1.5} />
          <Tips />
          <Spacer vertical={1.5} />
          <Separator orientation="row" />
          <Spacer vertical={2} />
          <SelectedPhotos
            waitingSelectPhoto={!!waitingSelectPhoto}
            onPressAddPhoto={selectAddPhotoMethod}
            processingCount={processingPhotos}
            selectedPhotos={selectedPhotos}
            onUnselect={onUnselect}
            uploadProgress={uploadProgress}
            waitingSubmit={waitingSubmit}
          />
        </View>
      </ScrollView>
      <BottomActionBar
        primaryAction={{
          type: "submit",
          actionText: strings.submit,
          onPressAction: createRecipe,
          disabled: selectedPhotos.length === 0,
          waiting: waitingSubmit,
        }}
        containerBackgroundColor="transparent"
      />
    </ScreenView>
  );
});

const SelectedPhotos = React.memo(
  (props: {
    waitingSelectPhoto: boolean;
    processingCount: number;
    selectedPhotos: AppAddPhotoArgs[];
    onUnselect: (photo: AppAddPhotoArgs) => void;
    onPressAddPhoto: () => void;
    uploadProgress: Record<string, number>;
    waitingSubmit: boolean;
  }) => {
    const focused = useScreen().nav.focused;

    useEffect(() => {
      if (focused) {
        layoutAnimation();
      }
    }, [props.processingCount, props.selectedPhotos, props.uploadProgress, props.waitingSelectPhoto]);

    return (
      <View style={{ flexDirection: "row", justifyContent: "space-around" }}>
        {props.selectedPhotos.map((sp, idx) => {
          return (
            <SelectedPhoto
              key={sp.uri}
              index={idx}
              photo={sp}
              onDelete={props.onUnselect}
              uploadPercentComplete={props.uploadProgress[sp.uri]}
            />
          );
        })}
        {range(0, props.processingCount - 1).map(idx => {
          return <PhotoPlaceholder key={idx} index={idx} />;
        })}
        {props.selectedPhotos.length + props.processingCount < maxPhotos && (
          <AddPhotosButton
            index={props.selectedPhotos.length + props.processingCount}
            onPress={props.onPressAddPhoto}
            waiting={props.waitingSelectPhoto}
            disabled={props.waitingSubmit}
          />
        )}
        {props.selectedPhotos.length + props.processingCount + 1 < maxPhotos && (
          <View style={{ flex: maxPhotos - props.selectedPhotos.length - props.processingCount - 1 }} />
        )}
      </View>
    );
  }
);

const AddPhotosButton = React.memo(
  (props: { index: number; onPress: () => void; waiting: boolean; disabled: boolean }) => {
    const marginLeft = props.index !== 0 ? { marginLeft: globalStyleConstants.unitSize } : {};

    return (
      <Pressable
        style={[styles.photo, styles.addPhotoButton, marginLeft]}
        onPress={props.onPress}
        disabled={props.disabled || props.waiting}
      >
        <ContainerFlexCentered>
          {!!props.waiting && <Spinner size={30} />}
          {!props.waiting && <IconCamera opacity="opaque" size={30} color={globalStyleColors.colorAccentCool} />}
          <Spacer vertical={0.5} />
          <TSecondary fontWeight="medium">{strings.addPhotos}</TSecondary>
        </ContainerFlexCentered>
      </Pressable>
    );
  }
);

const PhotoPlaceholder = React.memo((props: { index: number }) => {
  const marginLeft = props.index !== 0 ? { marginLeft: globalStyleConstants.unitSize } : {};

  return (
    <View style={[styles.photo, styles.processing, marginLeft]}>
      <FlexedSpinner />
    </View>
  );
});

const SelectedPhoto = React.memo(
  (props: {
    index: number;
    photo: AppAddPhotoArgs;
    uploadPercentComplete?: number;
    onDelete: (photo: AppAddPhotoArgs) => void;
  }) => {
    const screen = useScreen();

    const onPressPhoto = useCallback(() => {
      screen.nav.modal(navTree.get.screens.viewPhoto, { photo: props.photo.uri });
    }, [screen.nav.modal, props.photo.uri]);

    const marginLeft = props.index !== 0 ? { marginLeft: globalStyleConstants.unitSize } : {};

    return (
      <Pressable onPress={onPressPhoto} style={[styles.photo, marginLeft]}>
        <Image
          style={{
            width: "100%",
            height: "100%",
            borderRadius: globalStyleConstants.defaultBorderRadius,
            overflow: "hidden",
          }}
          source={{ uri: props.photo.uri }}
          resizeMode="cover"
        />
        {!!props.uploadPercentComplete && (
          <View
            style={[
              StyleSheet.absoluteFill,
              {
                backgroundColor: globalStyleColors.rgba("black", "light"),
                justifyContent: "center",
                alignItems: "center",
                borderRadius: globalStyleConstants.defaultBorderRadius,
              },
            ]}
          >
            {props.uploadPercentComplete < 100 && (
              <>
                <PhotoUploadProgressBar progress={props.uploadPercentComplete} />
              </>
            )}
            {props.uploadPercentComplete === 100 && <IconCircleCheck opacity="opaque" color={"white"} />}
          </View>
        )}
        {!props.uploadPercentComplete && <UnselectPhotoButton onPress={() => props.onDelete(props.photo)} />}
      </Pressable>
    );
  }
);

const PhotoUploadProgressBar = React.memo((props: { progress: number }) => {
  return (
    <View style={styles.progressBarWrap}>
      <View style={[styles.progressBar, { width: `${props.progress}%` }]}>
        <ShimmerEffect backgroundColor="colorAccentCool" />
      </View>
    </View>
  );
});

const UnselectPhotoButton = React.memo((props: { onPress: () => void }) => {
  const offset = 0.5 * globalStyleConstants.unitSize;

  return (
    <View style={{ position: "absolute", top: -offset, right: -offset }}>
      <Pressable hitSlop={globalStyleConstants.unitSize} style={styles.unselectPhotoButton} onPress={props.onPress}>
        <IconEx opacity="opaque" color={globalStyleColors.black} size={16} strokeWidth={2} />
      </Pressable>
    </View>
  );
});

const Tips = React.memo(() => {
  return (
    <>
      <TSecondary fontWeight="medium" opacity="dark">
        {strings.tips}
      </TSecondary>
      {strings.tipsBullets.map((i, idx) => {
        return <ParagraphComponent key={idx} fragments={[i]} enumeration={idx + 1} textOpacity="dark" />;
      })}
    </>
  );
});

const AddPhotoOptionsSheet = React.memo((props: { takePhoto: () => void; selectFromLibrary: () => void }) => {
  const bottomSheet = useBottomSheet();

  const doAction = useCallback(
    (type: "camera" | "library") => {
      bottomSheet?.closeSheetAndGoBack();
      setTimeout(() => {
        switch (type) {
          case "camera": {
            props.takePhoto();
            break;
          }
          case "library": {
            props.selectFromLibrary();
            break;
          }
          default:
            bottomThrow(type);
        }
      }, 200);
    },
    [bottomSheet]
  );

  const onPressTakePhoto = useCallback(() => {
    doAction("camera");
  }, [doAction]);

  const onPressSelectFromLibrary = useCallback(() => {
    doAction("library");
  }, [doAction]);

  return (
    <OptionsMenu>
      <OptionsMenuItem icon="camera" text={strings.takePhoto} onPress={onPressTakePhoto} isFirst />
      <OptionsMenuItem icon="image" text={strings.selectPhotos} onPress={onPressSelectFromLibrary} />
    </OptionsMenu>
  );
});

const styles = StyleSheet.create({
  photo: {
    flex: 1,
    aspectRatio: 1,
    maxHeight: 256,
    maxWidth: 256,
    borderRadius: globalStyleConstants.defaultBorderRadius,
  },
  processing: {
    borderWidth: 1,
    borderColor: globalStyleColors.colorAccentCool,
    borderStyle: "dashed",
  },
  addPhotoButton: {
    borderWidth: 1,
    borderColor: globalStyleColors.colorAccentCool,
    borderStyle: "dashed",
  },
  progressBarWrap: {
    width: "50%",
    height: 11,
    padding: 0.5,
    borderRadius: Number.MAX_SAFE_INTEGER,
    backgroundColor: "white",
    overflow: "hidden",
  },
  progressBar: {
    flex: 1,
    borderRadius: Number.MAX_SAFE_INTEGER,
    overflow: "hidden",
  },
  unselectPhotoButton: {
    borderRadius: Number.MAX_SAFE_INTEGER,
    padding: 4,
    backgroundColor: globalStyleColors.white,
    ...globalStyles.shadow,
  },
});
