import React, { PropsWithChildren, useCallback, useEffect, useImperativeHandle, useMemo } from "react";
import { useScreen } from "../navigation/ScreenContainer";
import Animated, {
  WithDecayConfig,
  WithSpringConfig,
  interpolate,
  runOnJS,
  useAnimatedStyle,
  useSharedValue,
  withDecay,
  withSpring,
} from "react-native-reanimated";
import { Keyboard, StyleSheet, View } from "react-native";
import { globalStyleColors } from "./GlobalStyles";
import { Pressable } from "./Pressable";
import { Gesture, GestureDetector } from "react-native-gesture-handler";
import { useBottomTabBarDimensions } from "../navigation/TabBar";

const config = {
  overlayColor: globalStyleColors.rgba("black", "medium"),
  sheet: {
    openAnimationConfig: { stiffness: 110, mass: 0.4, damping: 12 } as WithSpringConfig,
    closeAnimationConfig: { velocity: 2500, deceleration: 1.0 } satisfies Required<
      Pick<WithDecayConfig, "velocity" | "deceleration">
    > &
      WithDecayConfig,
    panGesture: {
      overshootFriction: 4,
      closeThresholdPoint: Number.MAX_SAFE_INTEGER,
      closeThresholdVelocity: 1000,
    },
    defaultColor: globalStyleColors.colorGreyLight,
  },
  notch: {
    height: 4,
    width: 50,
    paddingVertical: 8,
  },
};

export interface BottomSheetProps {
  height: number;
  backgroundColor?: string;
  notchColor?: string;
  keyboardDismissMode?: "none" | "onDrag";
  disableAutoOpen?: boolean;
  disableGestureDismissal?: boolean;
}

export interface BottomSheetImperativeHandle {
  closeSheetAndGoBack: () => void;
  openSheet: () => void;
}

/**
 * Opens an animated view container that slides up from the bottom of the screen. This component is intended
 * to be used with navigation `presentation: "transparentModal"` as the first child of `ScreenView`
 */
export const BottomSheet = React.memo(
  React.forwardRef<BottomSheetImperativeHandle, PropsWithChildren<BottomSheetProps>>((props, ref) => {
    const screen = useScreen();
    const { bottomNotchHeight } = useBottomTabBarDimensions();
    const sheetHeight = useSharedValue(0);

    const openedHeight = bottomNotchHeight + props.height + config.notch.height + 2 * config.notch.paddingVertical;

    const openSheet = useCallback(() => {
      sheetHeight.value = withSpring(openedHeight, config.sheet.openAnimationConfig);
    }, [sheetHeight, openedHeight]);

    const goBack = useCallback(() => screen.nav.goBack(), [screen.nav.goBack]);

    const dismissKeyboardOnPan = useCallback(() => {
      if (props.keyboardDismissMode === "onDrag") {
        Keyboard.dismiss();
      }
    }, [props.keyboardDismissMode]);

    const closeSheetAndGoBack = useCallback(
      (velocityY: number = config.sheet.closeAnimationConfig.velocity) => {
        Keyboard.dismiss();
        const velocity = Math.max(velocityY, config.sheet.closeAnimationConfig.velocity);
        sheetHeight.value = withDecay(
          {
            velocity: -velocity,
            deceleration: config.sheet.closeAnimationConfig.deceleration,
            clamp: [0, sheetHeight.value],
          },
          () => runOnJS(goBack)()
        );
      },
      [sheetHeight, goBack]
    );

    useEffect(() => {
      if (props.disableAutoOpen) {
        return;
      }
      openSheet();
    }, []);

    useImperativeHandle(ref, () => ({ closeSheetAndGoBack, openSheet }), [closeSheetAndGoBack, openSheet]);

    const heightAnimation = useAnimatedStyle(() => {
      return {
        height: sheetHeight.value,
      };
    }, [sheetHeight]);

    const opacityAnimation = useAnimatedStyle(() => {
      return {
        opacity: interpolate(sheetHeight.value, [0, openedHeight], [0, 1]),
      };
    }, [sheetHeight, openedHeight]);

    const panGestureHandler = useMemo(
      () =>
        Gesture.Pan()
          .enabled(!props.disableGestureDismissal)
          .onStart(() => {
            runOnJS(dismissKeyboardOnPan)();
          })
          .onUpdate(e => {
            if (e.translationY < 0) {
              sheetHeight.value = openedHeight - e.translationY / config.sheet.panGesture.overshootFriction;
              return;
            }
            sheetHeight.value = openedHeight - e.translationY;
          })
          .onEnd(e => {
            if (
              e.velocityY > config.sheet.panGesture.closeThresholdVelocity ||
              sheetHeight.value < openedHeight - config.sheet.panGesture.closeThresholdPoint
            ) {
              runOnJS(closeSheetAndGoBack)(e.velocityY);
              return;
            }
            runOnJS(openSheet)();
          }),
      [props.disableGestureDismissal, openedHeight, closeSheetAndGoBack, dismissKeyboardOnPan, openSheet, sheetHeight]
    );

    const sheetColor = props.backgroundColor ? { backgroundColor: props.backgroundColor } : {};

    return (
      <>
        <Animated.View style={[styles.overlay, opacityAnimation]}>
          <Pressable style={{ flex: 1 }} onPress={closeSheetAndGoBack} disabled={!!props.disableGestureDismissal} />
        </Animated.View>
        <GestureDetector gesture={panGestureHandler}>
          <Animated.View style={[styles.sheet, sheetColor, heightAnimation]}>
            {!props.disableGestureDismissal && <SheetNotch color={props.notchColor} />}
            {props.children}
          </Animated.View>
        </GestureDetector>
      </>
    );
  })
);

const SheetNotch = React.memo((props: { color?: string }) => {
  const notchColor = props.color ? { backgroundColor: props.color } : {};

  return (
    <View style={styles.notchWrap}>
      <View style={[styles.notch, notchColor]} />
    </View>
  );
});

const styles = StyleSheet.create({
  overlay: {
    ...StyleSheet.absoluteFillObject,
    backgroundColor: config.overlayColor,
  },
  sheet: {
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: config.sheet.defaultColor,
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
  },
  notchWrap: {
    alignItems: "center",
    paddingVertical: config.notch.paddingVertical,
  },
  notch: {
    height: config.notch.height,
    width: config.notch.width,
    backgroundColor: "#DBDEE8",
  },
});
