import { useScreen, withNonNavigableScreenContainer } from "../../navigation/ScreenContainer";
import { ScreenView } from "../../components/ScreenView";
import { useCallback } from "react";
import { useDispatch } from "../../lib/redux/Redux";
import { onboardingQuestionAnswered } from "../../lib/system/SystemSlice";
import { onboardingScreenHeader } from "../../components/onboarding/OnboardingScreenHeader";
import { Paragraph, ParagraphComponent } from "../../components/LongformText";
import { OnboardingScreenHeadline } from "../../components/onboarding/OnboardingHeadline";
import { useSurveyOptions } from "../../components/SurveyOptions";
import { OnboardingCtaButton } from "../../components/onboarding/OnboardingCtaButton";
import { Image, View } from "react-native";
import { useResponsiveDimensions } from "../../components/Responsive";
import { Spacer } from "../../components/Spacer";
import { bottomActionBarConstants } from "../../components/BottomActionBar";
import { navToNextOnboardingScreen } from "../../navigation/NavThunks";

const groceryImage = require("../../assets/onboarding/grocery.png");

const strings = {
  headline: "Do you want help with your groceries?",
  subheadline: {
    firstParagraph: [
      {
        type: "text",
        text: "You probably already have a system that kinda works. But hear us out: ",
      },
    ],
    secondParagraph: [
      {
        type: "text",
        text: "Once you try the Deglaze grocery list, we don't think you'll go back.",
        fontWeight: "medium",
      },
      {
        type: "text",
        text: " Thousands of users agree.",
      },
    ],
  } satisfies Record<string, Paragraph["fragments"]>,
  surveyOptions: {
    yes: "I want to try it",
    no: "No thanks",
  } satisfies Record<"yes" | "no", string>,
};

export const OnboardingGroceryScreen = withNonNavigableScreenContainer("OnboardingGroceryScreen", () => {
  const { nav } = useScreen();
  const dispatch = useDispatch();
  const { defaultFontScale } = useResponsiveDimensions();
  const surveyOptions = useSurveyOptions(
    "singleSelect",
    Object.entries(strings.surveyOptions).map(([key, displayText]) => ({ key, displayText }))
  );

  const onNext = useCallback(() => {
    dispatch(
      onboardingQuestionAnswered({
        tryGroceries: surveyOptions.selected[0]?.key === ("yes" satisfies keyof typeof strings.surveyOptions),
      })
    );
    dispatch(navToNextOnboardingScreen(nav, "replace"));
  }, [dispatch, surveyOptions.selected, nav.goTo]);

  return (
    <ScreenView scrollView={false} header={onboardingScreenHeader}>
      <View style={{ flex: 1, paddingBottom: bottomActionBarConstants.height, justifyContent: "space-between" }}>
        <View>
          <OnboardingScreenHeadline
            headline={strings.headline}
            subHeadline={() => (
              <>
                <ParagraphComponent scale={defaultFontScale} fragments={strings.subheadline.firstParagraph} isFirst />
                <ParagraphComponent scale={defaultFontScale} fragments={strings.subheadline.secondParagraph} />
              </>
            )}
            paddingBottomUnits={0}
          />
        </View>
        <Spacer vertical={1} />
        <GroceryImage />
        <Spacer vertical={1} />
        <View>{surveyOptions.renderSurveyOptions()}</View>
      </View>
      <OnboardingCtaButton
        cta="next"
        background="transparent"
        onPress={onNext}
        disabled={surveyOptions.selected.length === 0}
      />
    </ScreenView>
  );
});

const GroceryImage = () => {
  const { height: screenHeight } = useResponsiveDimensions();

  const aspectRatio = 815 / 693;
  const imageHeight = 0.35;

  return (
    <Image
      source={groceryImage}
      resizeMode="contain"
      style={{
        alignSelf: "center",
        width: (imageHeight * screenHeight) / aspectRatio,
        height: imageHeight * screenHeight,
      }}
    />
  );
};
