import { useSelector } from "../redux/Redux";
import { EntityResult, SearchQuery, SearchResults } from "@eatbetter/search-shared";
import { RootState } from "../redux/RootReducer";
import { discriminate } from "@eatbetter/common-shared";
import { AppSearchRecipeResult, SearchSessionId, selectSearchSession } from "./SearchSlice";
import { getCreateSelectorWithCacheSize } from "../redux/CreateSelector";
import { selectLibraryRecipesBySourceId } from "../recipes/RecipesSelectors";

export const selectSearchQuery = (s: RootState, sessionId: SearchSessionId): SearchQuery | undefined => {
  const search = selectSearchSession(s.search, sessionId);
  if (!search) {
    return undefined;
  }

  return {
    query: search.filters.search,
    tags: search.filters.tags?.filter(discriminate("type", "system")) ?? [],
    maxTotalTime: search.filters.time?.find(t => t.type === "totalTime")?.totalTime,
    authorId: search.authorId,
    publisherId: search.publisherId,
  };
};

export const selectSearchSuggestions = getCreateSelectorWithCacheSize(100)(
  [(s: RootState, sessionId: SearchSessionId) => selectSearchSession(s.search, sessionId)],
  session => {
    const queryNormalized = session?.filters.search?.toLowerCase().trim();
    if (!session || !queryNormalized) {
      return [];
    }

    return session.suggestions[queryNormalized] ?? [];
  }
);

const selectServerSearchResults: (
  state: RootState,
  sessionId: SearchSessionId
) => Pick<SearchResults, "recipes" | "entities"> = getCreateSelectorWithCacheSize(5)(
  [
    (state: RootState, sessionId: SearchSessionId) => selectSearchSession(state.search, sessionId)?.requests,
    (state: RootState) => selectLibraryRecipesBySourceId(state),
  ],
  (requests, libraryRecipes) => {
    const recipes: AppSearchRecipeResult[] = [];
    const entities: EntityResult[] = [];
    requests?.forEach(r => {
      const recipesToAdd =
        r.results?.recipes.map(i =>
          // Return library recipe if found so we can include metadata (e.g. cook count) that we might want to display
          libraryRecipes[i.recipe.id] ? { ...i, saved: true, recipe: libraryRecipes[i.recipe.id] ?? i.recipe } : i
        ) ?? [];
      const entitiesToAdd = r.results?.entities ?? [];
      recipes.push(...recipesToAdd);
      entities.push(...entitiesToAdd);
    });

    return { recipes, entities };
  }
);

export const useSearchInputText = (sessionId: SearchSessionId): string | undefined =>
  useSelector(s => {
    const session = selectSearchSession(s.search, sessionId);
    const query = session?.filters.search;

    return query;
  });

export const useSearchQuerySubmitted = (sessionId: SearchSessionId): boolean =>
  useSelector(s => {
    const session = selectSearchSession(s.search, sessionId);
    const querySubmitted = session?.querySubmitted;

    return !!querySubmitted;
  });

export const useServerSearchResults = (sessionId: SearchSessionId): Pick<SearchResults, "recipes" | "entities"> =>
  useSelector(s => selectServerSearchResults(s, sessionId));

export const useSearchIsLoading = (sessionId: SearchSessionId): boolean =>
  useSelector(s => {
    const search = selectSearchSession(s.search, sessionId);
    return !!search?.requests.some(r => !r.results && !r.error);
  });

const selectServerRecipeResultCount = getCreateSelectorWithCacheSize(10)(
  [(state, sessionId) => selectSearchSession(state.search, sessionId)],
  search => {
    const results = search?.requests.find(r => !!r.results)?.results;
    if (results) {
      return { count: results.totalRecipeCount, maxExceeded: !!results.totalRecipeCountMightBeMore };
    }
    return undefined;
  }
);

export const useServerRecipeResultCount = (sessionId: SearchSessionId) => {
  return useSelector(state => selectServerRecipeResultCount(state, sessionId));
};

const selectQuerySuggestions: (
  s: RootState,
  sessionId: SearchSessionId
) => { recentQueries: string[]; suggestedQueries: string[] } = getCreateSelectorWithCacheSize(100)(
  [
    (s: RootState, sessionId: SearchSessionId) => selectSearchSession(s.search, sessionId)?.filters.search,
    (s: RootState) => s.search.recentQueries,
    (s: RootState, sessionId: SearchSessionId) => selectSearchSuggestions(s, sessionId),
  ],
  (query, recentQueries, suggestedQueries) => {
    const queryNormalized = query?.trim().toLowerCase();
    if (!queryNormalized) {
      return {
        recentQueries,
        suggestedQueries,
      };
    }

    const recentQueriesSet = new Set<string>();

    // Filter recent queries preserving original casing but checking against normalized query
    const filteredRecentQueries = recentQueries.filter(rq => {
      const rqNormalized = rq.toLowerCase();
      if (rqNormalized.startsWith(queryNormalized)) {
        recentQueriesSet.add(rqNormalized);
        return true;
      }
      return false;
    });

    // Filter suggested queries to exclude ones already present in the recent query set (dedupe)
    const filteredSuggestedQueries = suggestedQueries.filter(sq => !recentQueriesSet.has(sq.toLowerCase()));

    return {
      recentQueries: filteredRecentQueries,
      suggestedQueries: filteredSuggestedQueries,
    };
  }
);

export const useQuerySuggestions = (sessionId: SearchSessionId) => {
  return useSelector(s => selectQuerySuggestions(s, sessionId));
};

export const useSearchResultsScrolled = (sessionId: SearchSessionId): boolean => {
  return useSelector(s => !!selectSearchSession(s.search, sessionId)?.isScrolled);
};
