import React, { PropsWithChildren, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { LayoutAnimation, StyleSheet, View } from "react-native";
import { Slider } from "../Slider";
import { TBody, THeading2, TSecondary } from "../Typography";
import { Pressable } from "../Pressable";
import { IconSliders, IconUndo } from "../Icons";
import { globalStyleColors, globalStyleConstants } from "../GlobalStyles";
import { useScreen } from "../../navigation/ScreenContainer";
import { navTree } from "../../navigation/NavTree";
import { Spacer } from "../Spacer";
import { SectionHeading } from "../SectionHeading";
import { SegmentedControl, SegmentedControlProps } from "../SegmentedControl";
import { maxContentWidth, useResponsiveDimensions } from "../Responsive";
import { UnitConversion } from "@eatbetter/items-shared";
import { isNullOrUndefined } from "@eatbetter/common-shared";
import { Separator } from "../Separator";
import { RecipeYieldDisplay } from "./RecipeYield";
import { RecipeYield } from "@eatbetter/recipes-shared";
import { Haptics } from "../Haptics";

const strings = {
  sheet: {
    title: "Scale & Convert Recipe",
    scale: "Scale",
    convert: "Unit Conversions",
    conversionLabels: {
      original: "Original",
      metric: "Metric",
      imperial: "Imperial",
    },
  },
  scaleRecipe: "Scale Recipe",
};

export const scalingSymbol = "×";

const scalingSteps: Array<{ display?: string; value: number }> = [
  { display: "¼", value: 1 / 4 },
  { display: "⅓", value: 1 / 3 },
  { display: "½", value: 1 / 2 },
  { value: 1 },
  { display: "1½", value: 1.5 },
  { value: 2 },
  { display: "2½", value: 2.5 },
  { value: 3 },
  { display: "3½", value: 3.5 },
  { value: 4 },
  { value: 5 },
  { value: 6 },
  { value: 7 },
  { value: 8 },
];

/**
 * Matches the scaling step for the given scale value and if not found, returns the closest item. This way we can
 * use the slider down the line with arbitrary values and still position it reasonably.
 */
function getScalingStep(scale: number): { index: number; value: number; display: string } {
  // Tolerance for matching a value from scalingSteps
  const epsilon = 1e-4;

  // Find the index of the exact match, if any
  const exactMatchIndex = scalingSteps.findIndex(step => Math.abs(step.value - scale) < epsilon);

  const scalingStep = scalingSteps[exactMatchIndex];

  if (exactMatchIndex !== -1 && !isNullOrUndefined(scalingStep)) {
    // Exact match found
    return {
      index: exactMatchIndex,
      value: scalingStep.value,
      display: scalingStep.display ?? String(scalingStep.value),
    };
  } else {
    // No exact match; find the index of the closest step
    const closestIndex = scalingSteps.reduce((prevIndex, step, index) => {
      const prevStep = scalingSteps[prevIndex];
      if (isNullOrUndefined(prevStep)) {
        return prevIndex;
      }
      const prevDiff = Math.abs(prevStep.value - scale);
      const currentDiff = Math.abs(step.value - scale);
      return currentDiff < prevDiff ? index : prevIndex;
    }, 0);

    return {
      index: closestIndex,
      value: scale,
      display: getRecipeScaleDisplayString(scale),
    };
  }
}

/**
 * Returns a display string for the given scale value, trying to match unicode fractions for the fractional part
 * to a unicode fraction value if possible. Otherwise, returns 2 decimal places.
 */
export function getRecipeScaleDisplayString(scale: number): string {
  const integerPart = Math.trunc(scale);
  const fractionalPart = Math.abs(scale - integerPart);

  // Tolerance for matching fractions
  const epsilon = 1e-2;

  // Check if the fractional part is approximately zero
  if (Math.abs(fractionalPart) < epsilon) {
    return integerPart.toString();
  }

  // List of common fractions with their Unicode representations
  const fractions = [
    { numerator: 1, denominator: 2, unicode: "½" },
    { numerator: 1, denominator: 3, unicode: "⅓" },
    { numerator: 2, denominator: 3, unicode: "⅔" },
    { numerator: 1, denominator: 4, unicode: "¼" },
    { numerator: 3, denominator: 4, unicode: "¾" },
    { numerator: 1, denominator: 8, unicode: "⅛" },
  ];

  let matchedFraction = null;

  // Try to match the fractional part to a known fraction
  for (const fraction of fractions) {
    const fractionValue = fraction.numerator / fraction.denominator;
    if (Math.abs(fractionalPart - fractionValue) < epsilon) {
      matchedFraction = fraction;
      break;
    }
  }

  if (matchedFraction) {
    let result = "";

    if (integerPart !== 0) {
      result = integerPart.toString();
    }

    // Handle negative numbers with zero integer part
    if (integerPart === 0 && scale < 0) {
      result = "-";
    }

    result += matchedFraction.unicode;
    return result;
  } else {
    // Format the scale to two decimal places
    return scale.toFixed(2);
  }
}

interface RecipeScalingControlProps {
  scale: number;
  onChangeScale: (v: number) => void;
}

export const RecipeScalingControl = React.memo((props: RecipeScalingControlProps) => {
  const scalingStep = useMemo(() => getScalingStep(props.scale), [props.scale]);
  const initialSliderValue = useRef(scalingStep.index);
  const [refreshSlider, setRefreshSlider] = useState(0);

  const onChangeScale = useCallback(
    (index: number) => {
      const step = scalingSteps[index];
      if (step !== undefined) {
        LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
        props.onChangeScale(step.value);
      }
    },
    [props.onChangeScale]
  );

  const onPressUndoScale = useCallback(() => {
    const scaleStepIndex = scalingSteps.findIndex(i => i.value === 1);
    Haptics.feedback("itemStatusChanged");
    onChangeScale(scaleStepIndex);
    setRefreshSlider(prev => prev + 1);
  }, [onChangeScale, setRefreshSlider]);

  return (
    <View style={[styles.controlWrap, { flex: 1, paddingHorizontal: 1.5 * globalStyleConstants.unitSize }]}>
      <View style={styles.scalingControl}>
        <View style={styles.scalingTextWrap}>
          <ScaleSymbol position="start" />
          <TBody fontWeight="medium" color={globalStyleColors.colorAccentCool}>
            {scalingStep.display}
          </TBody>
        </View>
        <Slider
          key={refreshSlider}
          minValue={0}
          maxValue={scalingSteps.length - 1}
          stepCount={scalingSteps.length - 1}
          onValueChange={onChangeScale}
          initialValue={initialSliderValue.current}
        />
      </View>
      {props.scale !== 1 && (
        <Pressable onPress={onPressUndoScale} style={styles.undoButton}>
          <IconUndo size={18} color={globalStyleColors.colorAccentCool} strokeWidth={2.5} />
        </Pressable>
      )}
    </View>
  );
});

const ScaleSymbol = React.memo((props: { position: "start" | "end" }) => {
  return (
    <THeading2>
      {props.position === "end" && <THeading2> </THeading2>}
      <THeading2>{scalingSymbol}</THeading2>
      {props.position === "start" && <THeading2> </THeading2>}
    </THeading2>
  );
});

export interface ScalingAndConversionsButtonProps extends ScalingAndConversionsSheetProps {
  showLabel?: boolean;
  strokeWidth?: "normal" | "medium";
  inactiveColor?: string;
  showBadge?: boolean;
  disabled?: boolean;
}

export const ScalingAndConversionsButton = React.memo((props: ScalingAndConversionsButtonProps) => {
  const { navToScalingAndConversionsSheet } = useScalingAndConversionsSheet({
    recipeTitle: props.recipeTitle,
    recipeYield: props.recipeYield,
    scale: props.scale,
    onChangeScale: props.onChangeScale,
    unit: props.unit,
    onChangeUnit: props.onChangeUnit,
  });

  const isScaled = props.scale !== 1;
  const isUnitConverted = props.unit !== "original";

  const strokeWidth = props.strokeWidth ?? "normal";
  const badgeCount = Number(isScaled) + Number(isUnitConverted);

  const inactiveColor = props.inactiveColor ?? globalStyleColors.colorAccentCool;
  const color = badgeCount > 0 ? globalStyleColors.colorAccentCool : inactiveColor;

  return (
    <Pressable
      style={{ flexDirection: "row", alignItems: "center" }}
      onPress={navToScalingAndConversionsSheet}
      noFeedback
      disabled={props.disabled}
    >
      <IconSliders
        badgeCount={props.showBadge ? badgeCount : 0}
        strokeWidth={strokeWidth === "medium" ? 2 : undefined}
        color={color}
      />
      {!!props.showLabel && (
        <>
          <Spacer horizontal={1} />
          <TSecondary color={globalStyleColors.colorAccentCool} fontWeight="medium">
            {strings.scaleRecipe}
          </TSecondary>
        </>
      )}
    </Pressable>
  );
});

export const ScalingInlineControl = React.memo(
  (props: RecipeScalingControlProps & ScalingAndConversionsButtonProps) => {
    return (
      <View style={{ flexDirection: "row", alignItems: "center" }}>
        <RecipeScalingControl scale={props.scale} onChangeScale={props.onChangeScale} />
        <Spacer horizontal={1.5} />
        <ScalingAndConversionsButton
          strokeWidth="medium"
          recipeTitle={props.recipeTitle}
          recipeYield={props.recipeYield}
          scale={props.scale}
          onChangeScale={props.onChangeScale}
          unit={props.unit}
          onChangeUnit={props.onChangeUnit}
        />
      </View>
    );
  }
);

interface UnitConversionsControlProps {
  unit: UnitConversion;
  onChangeUnit: (v: UnitConversion) => void;
}

const UnitConversionsControl = React.memo((props: UnitConversionsControlProps) => {
  const dimensions = useResponsiveDimensions();

  const segments = useMemo<SegmentedControlProps["segments"]>(() => {
    return [
      {
        label: strings.sheet.conversionLabels.original,
        isSelected: props.unit === "original",
        onPress: () => props.onChangeUnit("original"),
      },
      {
        label: strings.sheet.conversionLabels.metric,
        isSelected: props.unit === "metric",
        onPress: () => props.onChangeUnit("metric"),
      },
      {
        label: strings.sheet.conversionLabels.imperial,
        isSelected: props.unit === "standard",
        onPress: () => props.onChangeUnit("standard"),
      },
    ];
  }, [props.unit, props.onChangeUnit]);

  return (
    <SegmentedControl
      height="large"
      width={dimensions.width - 2 * globalStyleConstants.defaultPadding}
      segments={segments}
    />
  );
});

export function useScalingAndConversionsSheet({
  recipeTitle,
  recipeYield,
  scale,
  onChangeScale,
  unit,
  onChangeUnit,
}: ScalingAndConversionsSheetProps) {
  const screen = useScreen();

  const navToScalingAndConversionsSheet = useCallback(() => {
    screen.nav.modal(navTree.get.screens.bottomSheet, {
      content: (
        <ScalingAndConversionsSheet
          recipeTitle={recipeTitle}
          recipeYield={recipeYield}
          scale={scale}
          onChangeScale={onChangeScale}
          unit={unit}
          onChangeUnit={onChangeUnit}
        />
      ),
      height: getScalingAndConversionsSheetHeight({ recipeYield, recipeTitle }),
      backgroundColor: "white",
    });
  }, [screen.nav.modal, scale, onChangeScale, unit, onChangeUnit]);

  return { navToScalingAndConversionsSheet };
}

function getScalingAndConversionsSheetHeight(args: {
  recipeTitle: string | undefined;
  recipeYield: RecipeYield | undefined;
}) {
  const baseHeight = 320;
  let height = baseHeight;

  if (args.recipeTitle) {
    height += 30;
  }
  if (args.recipeYield) {
    height += 18;
  }

  return height;
}

interface ScalingAndConversionsSheetProps extends RecipeScalingControlProps, UnitConversionsControlProps {
  recipeTitle: string | undefined;
  recipeYield: RecipeYield | undefined;
}

const ScalingAndConversionsSheet = React.memo((props: ScalingAndConversionsSheetProps) => {
  const [recipeScale, setRecipeScale] = useState(props.scale);
  const recipeScaleRef = useRef(recipeScale);
  const [recipeUnits, setRecipeUnits] = useState(props.unit);

  /**
   *  We want this to fire only when the component is dismounted, hence the lack of any deps
   *  We know the ref doesn't change, and we will assume the update functino won't change either.
   */
  useEffect(() => {
    return () => {
      if (recipeScaleRef.current !== props.scale) {
        props.onChangeScale(recipeScaleRef.current);
      }
    };
  }, []);

  const onChangeRecipeScale = useCallback(
    (value: number) => {
      // keep the ref up to date for the useEffect
      recipeScaleRef.current = value;
      setRecipeScale(value);
    },
    [setRecipeScale, recipeScaleRef]
  );

  const onChangeRecipeUnits = useCallback(
    (value: UnitConversion) => {
      //SCALE-TODO-DISPATCH: this is a hack to get this working as desired until we have a way to update scale (i.e. reactive with the background screen).
      props.onChangeUnit(value);
      setRecipeUnits(value);
    },
    [props.onChangeUnit, setRecipeUnits]
  );

  return (
    <View style={styles.sheetContainer}>
      <View style={styles.sheetMaxWidthContainer}>
        <View style={{ width: "100%", alignItems: "center" }}>
          <TBody fontWeight="medium">{strings.sheet.title}</TBody>
        </View>
        {(!!props.recipeTitle || !!props.recipeYield) && <Spacer vertical={1.5} />}
        {!!props.recipeTitle && <TBody numberOfLines={1}>{props.recipeTitle}</TBody>}
        <Spacer vertical={0.25} />
        {!!props.recipeYield && (
          <RecipeYieldDisplay
            yield={props.recipeYield}
            recipeScale={recipeScale}
            fontSize="body"
            italic
            opacity="dark"
          />
        )}
        <Spacer vertical={1} />
        <Separator orientation="row" />
        <Spacer vertical={2} />
        <SheetSection sectionTitle={strings.sheet.scale} noBorder noPadding>
          <RecipeScalingControl scale={recipeScale} onChangeScale={onChangeRecipeScale} />
        </SheetSection>
        <Spacer vertical={2.5} />
        <SheetSection sectionTitle={strings.sheet.convert} noPadding>
          <UnitConversionsControl unit={recipeUnits} onChangeUnit={onChangeRecipeUnits} />
        </SheetSection>
      </View>
    </View>
  );
});

const SheetSection = React.memo(
  (props: PropsWithChildren<{ sectionTitle: string; noPadding?: boolean; noBorder?: boolean }>) => {
    return (
      <>
        <SectionHeading text={props.sectionTitle} noPadding />
        <Spacer vertical={1} />
        <View
          style={[
            styles.controlWrap,
            props.noBorder ? { borderWidth: 0 } : {},
            props.noPadding ? {} : { paddingHorizontal: 1.5 * globalStyleConstants.unitSize },
          ]}
        >
          {props.children}
        </View>
      </>
    );
  }
);

const styles = StyleSheet.create({
  scalingControl: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
  },
  scalingTextWrap: {
    flexDirection: "row",
    alignItems: "center",
    width: 44,
  },
  sheetContainer: {
    width: "100%",
    alignItems: "center",
  },
  sheetMaxWidthContainer: {
    width: "100%",
    maxWidth: maxContentWidth,
    paddingTop: globalStyleConstants.unitSize,
    paddingHorizontal: globalStyleConstants.defaultPadding,
  },
  controlWrap: {
    height: 48,
    borderWidth: 0.5,
    borderColor: globalStyleColors.rgba("colorAccentCool", "medium"),
    borderRadius: 48,
  },
  undoButton: {
    position: "absolute",
    alignItems: "center",
    justifyContent: "center",
    right: -8,
    top: -18,
    width: 30,
    height: 30,
    borderRadius: 30,
    backgroundColor: "white",
    shadowColor: "black",
    shadowOpacity: 0.4,
    shadowRadius: 4,
    shadowOffset: { height: 4, width: 0 },
  },
});
