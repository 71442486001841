import React, { useCallback } from "react";
import { useScreen } from "../../navigation/ScreenContainer";
import { UserRecipeId } from "@eatbetter/recipes-shared";
import { navTree } from "../../navigation/NavTree";
import { getOptionsMenuHeight, OptionsMenu, OptionsMenuItem } from "../OptionsMenu";
import { useDispatch } from "../../lib/redux/Redux";
import { useBottomSheet } from "../../screens/BottomSheetScreen";
import { analyticsEvent } from "../../lib/analytics/AnalyticsThunks";
import { reportLibraryRecipeContextMenuItemTapped } from "../../lib/analytics/AnalyticsEvents";
import { useCookingSessionAudioEnabled } from "../../lib/cooking/CookingSessionsSelectors";
import { usePaywallStatus } from "../PaywallDetector";

const strings = {
  textSize: "Adjust Text Size",
  share: "Share Recipe",
  startAudio: "Start Audio Instructions",
  stopAudio: "Stop Audio Instructions",
  reportIssue: "Report a Recipe Issue",
};

export const recipeInKitchenOptionsHeight = getOptionsMenuHeight(4);

interface Props {
  recipeId: UserRecipeId;
  onTapAudio: () => void;
  onTapShare: () => void;
  onTapTextSize: () => void;
}

export const RecipeInKitchenOptions = React.memo((props: Props) => {
  const dispatch = useDispatch();
  const screen = useScreen();
  const bottomSheet = useBottomSheet();

  const audioOn = useCookingSessionAudioEnabled();

  const { paywallIsUp } = usePaywallStatus(props.recipeId);

  const onTapTextSize = useCallback(() => {
    bottomSheet?.closeSheetAndGoBack();
    setTimeout(() => props.onTapTextSize(), 300);
  }, [bottomSheet, props.onTapShare]);

  const onTapShare = useCallback(() => {
    bottomSheet?.closeSheetAndGoBack();
    setTimeout(() => props.onTapShare(), 300);
  }, [bottomSheet, props.onTapShare]);

  const onTapAudio = useCallback(() => {
    bottomSheet?.closeSheetAndGoBack();
    props.onTapAudio();
  }, [bottomSheet, props.onTapAudio]);

  const onPressReportIssue = useCallback(() => {
    bottomSheet?.closeSheetAndGoBack();
    dispatch(analyticsEvent(reportLibraryRecipeContextMenuItemTapped({ action: "reportIssue" })));
    setTimeout(() => screen.nav.goTo("push", navTree.get.screens.reportRecipeIssue, { recipeId: props.recipeId }), 300);
  }, [bottomSheet, screen.nav.goTo, props.recipeId]);

  return (
    <OptionsMenu>
      <OptionsMenuItem icon="text" isFirst text={strings.textSize} onPress={onTapTextSize} />
      <OptionsMenuItem icon="share" text={strings.share} onPress={onTapShare} />
      <OptionsMenuItem
        disabled={!audioOn && paywallIsUp}
        icon={audioOn ? "audioOn" : "audioOff"}
        text={audioOn ? strings.stopAudio : strings.startAudio}
        onPress={onTapAudio}
      />
      <OptionsMenuItem icon="flag" text={strings.reportIssue} onPress={onPressReportIssue} />
    </OptionsMenu>
  );
});
