import React, { useCallback, useEffect, useMemo, useState } from "react";
import { BottomActionBar, BottomActionBarProps, bottomActionBarConstants } from "../BottomActionBar";
import { useDispatch } from "../../lib/redux/Redux";
import { createCookingSession } from "../../lib/cooking/CookingSessionsThunks";
import { useScreen } from "../../navigation/ScreenContainer";
import { AppRecipe, PartialRecipeId, UserRecipeId, haveRecipeIngredients } from "@eatbetter/recipes-shared";
import { Haptics } from "../Haptics";
import { useIdempotentId } from "../../lib/util/UseIdempotentId";
import { displayUnexpectedErrorAndLog } from "../../lib/Errors";
import { CookingSessionId } from "@eatbetter/cooking-shared";
import { usePaywallStatus } from "../PaywallDetector";
import {
  useLibraryRecipe,
  useRecipeProcessingTimedOut,
  useRecipeSourceType,
  useRecipeStatus,
} from "../../lib/recipes/RecipesSelectors";
import { navTree, WebViewSignInHelpScreenProps } from "../../navigation/NavTree";
import { RecipeStatusBanner, RecipeStatusBannerProps } from "./RecipeStatusBanner";
import { bottomThrow, switchReturn } from "@eatbetter/common-shared";
import { LayoutAnimation } from "react-native";
import { analyticsEvent } from "../../lib/analytics/AnalyticsThunks";
import { reportRecipeStatusBannerDismissed } from "../../lib/analytics/AnalyticsEvents";
import { WebViewSessionId } from "../../lib/webview/WebViewSlice";
import { useWebViewCurrentUrl, useWebViewIsBrowseMode, useWebViewIsSignIn } from "../../lib/webview/WebViewSelectors";
import { addRecipeFromWebView } from "../../lib/recipes/RecipesThunks";
import { log } from "../../Log";
import { WalkthroughStep, WalkthroughStepProps } from "../Walkthrough";
import { useResponsiveDimensions } from "../Responsive";
import { useBottomTabBarHeight } from "@react-navigation/bottom-tabs";
import { THeading2, TSecondary } from "../Typography";
import { Spacer } from "../Spacer";
import { WebViewSignInHelp } from "./WebViewSignInHelp.tsx";

const strings = {
  startCooking: "Start Cooking",
  addToGroceries: "Add to Groceries",
  saveRecipe: "Add to Your Library",
  openRecipe: "Open Recipe",
  switchRecipe: "Switch Recipe",
  endCooking: "End Cooking",
  timerRemainingTitle: "A timer is running",
  timerRemainingBody: "It will be deleted if you end now. Do you still want to end cooking?",
  walkthroughSaveRecipeHead: "Save Recipe",
  walkthroughSaveRecipeSub: "Save recipes to your library. Once saved, you'll see buttons to shop and cook here.",
  walkthroughRecipeActionsHead: "Recipe Actions",
  walkthroughRecipeActionsSub: "Shop or cook your recipes with one tap.",
};

// we render one of three things in the same slot at the bottom, or nothing in the case the status
// banner is dismissed and we have no recipe
type BottomControlProps =
  | { type: "bottomActionBar"; props: BottomActionBarProps & { walkthroughText: string | React.ReactElement } }
  | { type: "statusBanner"; props: RecipeStatusBannerProps }
  | { type: "signInHelp"; props: WebViewSignInHelpScreenProps };

export function useRecipeDetailActionBarHeight(): { height: number; onChangeHeight: (e: { height: number }) => void } {
  const [height, setHeight] = useState(0);

  const onChangeHeight = useCallback(
    (e: { height: number }) => {
      setHeight(e.height);
    },
    [setHeight]
  );

  return useMemo(() => ({ height, onChangeHeight }), [height, onChangeHeight]);
}

export interface RecipeDetailActionBarProps {
  webViewSessionId?: WebViewSessionId;
  recipe: AppRecipe;
  onSaveRecipe?: () => Promise<void>;
  waitingSaveRecipe?: boolean;
  topBorderWidth?: "thin" | "thick";
  onChangeHeight?: (e: { height: number }) => void;
  context: "library" | "search" | "share" | "post";
  showWalkthrough: boolean;
  onWalkthroughNext: () => void;
}

export const RecipeDetailActionBar = React.memo((props: RecipeDetailActionBarProps) => {
  const dispatch = useDispatch();
  const screen = useScreen();

  const [newCookingSessionId, refreshNewCookingSessionId] = useIdempotentId<CookingSessionId>();
  const [newRecipeId, refreshNewRecipeId] = useIdempotentId<PartialRecipeId>();
  const [waitingCookNow, setWaitingCookNow] = useState(false);
  const [statusBannerDismissed, setStatusBannerDismissed] = useState(false);
  const [waitingSaveRecipeWebView, setWaitingSaveRecipeWebView] = useState(false);
  const [savedWebViewRecipes, setSavedWebViewRecipes] = useState<Record<string, UserRecipeId>>({});

  const recipe = props.recipe;
  const haveIngredients = haveRecipeIngredients(recipe.ingredients);
  const isUserPhotoRecipe = useRecipeSourceType(props.recipe) === "userPhoto";
  const { paywallStatus } = usePaywallStatus(recipe);

  const libraryRecipe = useLibraryRecipe(props.recipe.id);
  const libraryRecipeId = libraryRecipe?.id;
  const recipeStatus = useRecipeStatus(libraryRecipeId);
  const recipeProcessingTimedOut = useRecipeProcessingTimedOut(libraryRecipeId);

  const webViewCurrentUrl = useWebViewCurrentUrl(props.webViewSessionId);
  const isBrowseMode = useWebViewIsBrowseMode(props.webViewSessionId);
  const signInHelpDomain = useWebViewIsSignIn(props.webViewSessionId);

  // once we go to browse mode, dismiss the status banner
  // this is the same as the onDismissStatusBanner callback, except we don't fire the mixpanel event
  // SYNC EDITS BELOW IF APPROPRIATE
  useEffect(() => {
    if (isBrowseMode) {
      LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
      setStatusBannerDismissed(true);
    }
  }, [isBrowseMode, setStatusBannerDismissed]);

  // same as above, but we fire the mixpanel event since the user took action
  // SYNC EDITS ABOVE IF APPROPRIATE
  const onDismissStatusBanner = useCallback(() => {
    LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
    setStatusBannerDismissed(true);
    if (libraryRecipe) {
      dispatch(analyticsEvent(reportRecipeStatusBannerDismissed({ recipe: libraryRecipe })));
    }
  }, [setStatusBannerDismissed, libraryRecipe]);

  const topBorderWidth = props.topBorderWidth ?? "thin";

  const onAddToGroceries = useCallback(async () => {
    if (!libraryRecipeId) {
      displayUnexpectedErrorAndLog(
        "RecipeBottomActionBar.onAddToGroceries() called but libraryRecipeId is falsy",
        {},
        { libraryRecipeId }
      );
      return;
    }

    Haptics.feedback("itemStatusChanged");
    screen.nav.modal(navTree.get.screens.recipeAddToGrocery, { recipeId: libraryRecipeId });
  }, [libraryRecipeId, dispatch, screen.nav.modal]);

  const onPressCookNow = useCallback(async () => {
    if (!libraryRecipeId) {
      displayUnexpectedErrorAndLog(
        "RecipeBottomActionBar.onPressCookNow() called but userRecipeId is falsy",
        {},
        { libraryRecipeId }
      );
      return;
    }

    await dispatch(
      createCookingSession(
        {
          id: newCookingSessionId,
          sourceRecipeId: libraryRecipeId,
          paywallDetectionOutcome: paywallStatus,
        },
        setWaitingCookNow
      )
    );

    // We navigate differently if we're in the recipes tab versus in another tab. We always want cooking sessions to open in
    // the recipes tab to keep things simpler.
    switch (props.context) {
      case "library": {
        screen.nav.goTo("replace", navTree.get.screens.recipeInKitchen);
        break;
      }
      case "post":
      case "search":
      case "share": {
        // We first want to close out the recipe detail screen and then switch tabs. If the user goes back and re-opens the recipe,
        // they will be navigated back to the cooking session. This is consistent with the recipes tab case above.
        screen.nav.goBack();
        screen.nav.switchTab("recipesTab", navTree.get.screens.recipeInKitchen);
        break;
      }
      default:
        bottomThrow(props.context);
    }

    refreshNewCookingSessionId();
  }, [
    dispatch,
    newCookingSessionId,
    libraryRecipeId,
    setWaitingCookNow,
    screen.nav.switchTab,
    refreshNewCookingSessionId,
    paywallStatus,
  ]);

  const addRecipeFromCurrentUrl = useCallback(async () => {
    if (!webViewCurrentUrl) {
      log.error("addRecipeFromCurrentUrl(): webViewCurrentUrl is undefined");
      return;
    }

    let savedRecipeId: UserRecipeId;
    try {
      Haptics.feedback("itemStatusChanged");
      savedRecipeId = await dispatch(
        addRecipeFromWebView({ id: newRecipeId, url: webViewCurrentUrl }, setWaitingSaveRecipeWebView)
      );
      Haptics.feedback("operationSucceeded");
    } catch (err) {
      displayUnexpectedErrorAndLog("Error in addRecipeFromCurrentUrl()", err, { webViewCurrentUrl });
      return;
    }

    refreshNewRecipeId();
    setSavedWebViewRecipes(prev => {
      return { ...prev, [webViewCurrentUrl]: savedRecipeId } satisfies Record<string, UserRecipeId>;
    });
  }, [webViewCurrentUrl, newRecipeId, refreshNewRecipeId, setSavedWebViewRecipes]);

  const navToLibraryRecipe = useCallback(() => {
    const recipeId = webViewCurrentUrl ? savedWebViewRecipes[webViewCurrentUrl] : undefined;

    if (!recipeId) {
      displayUnexpectedErrorAndLog(
        "navToRecipe() called but recipeId is undefined",
        {},
        { webViewCurrentUrl, savedWebViewRecipes, recipeId }
      );
      return;
    }

    // We always open in the library for now to keep things sane.
    screen.nav.switchTab("recipesTab", navTree.get.screens.recipeDetail, { recipeId });
  }, [screen.nav.switchTab, savedWebViewRecipes]);

  const actions = useMemo<BottomControlProps | undefined>(() => {
    // Recipe status banner display logic
    if (libraryRecipe) {
      let bannerType: RecipeStatusBannerProps["type"] | undefined;

      if (isUserPhotoRecipe && recipeStatus === "processing") {
        // For userPhoto recipes, show the processing banner until it's done processing (unless it times out)
        bannerType = "workingOnIt";
      } else if (!statusBannerDismissed && !haveIngredients && !isBrowseMode) {
        bannerType = switchReturn(recipeStatus, {
          processing: "workingOnIt",
          pendingManual: "workingOnIt",
          complete: "noRecipe",
          failed: "noRecipe",
        });
      }

      if (bannerType) {
        if (bannerType === "workingOnIt" && recipeProcessingTimedOut) {
          bannerType = "noRecipe";
        }

        return {
          type: "statusBanner",
          props: {
            type: bannerType,
            recipe: libraryRecipe,
            onDismiss: onDismissStatusBanner,
          },
        };
      }
    }

    const showTapHint = props.showWalkthrough ? { showTapHint: { delay: 300 } } : {};

    const getWalkthroughText = (head: string, subhead?: string) => (
      <>
        <THeading2>{head}</THeading2>
        {!!subhead && (
          <>
            <Spacer vertical={0.5} />
            <TSecondary align="center">{subhead}</TSecondary>
          </>
        )}
      </>
    );
    const libraryActionsText = getWalkthroughText(
      strings.walkthroughRecipeActionsHead,
      strings.walkthroughRecipeActionsSub
    );
    const saveRecipeText = getWalkthroughText(strings.walkthroughSaveRecipeHead, strings.walkthroughSaveRecipeSub);

    // this should come before isBrowseMode since isBrowseMode will be true as well
    if (!!signInHelpDomain && props.webViewSessionId) {
      return {
        type: "signInHelp",
        props: {
          domain: signInHelpDomain,
          sessionId: props.webViewSessionId,
        },
      };
    }

    // this must come after signInHelpDomain check. Otherwise, rendering weirdness ensues.
    if (isBrowseMode) {
      const alreadyAdded = !!webViewCurrentUrl && !!savedWebViewRecipes[webViewCurrentUrl];
      LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
      return {
        type: "bottomActionBar",
        props: {
          primaryAction: {
            actionIcon: alreadyAdded ? undefined : "library",
            actionText: alreadyAdded ? strings.openRecipe : strings.saveRecipe,
            onPressAction: alreadyAdded ? navToLibraryRecipe : addRecipeFromCurrentUrl,
            waiting: waitingSaveRecipeWebView,
            ...showTapHint,
          },
          walkthroughText: saveRecipeText,
        },
      };
    }

    if (props.onSaveRecipe) {
      return {
        type: "bottomActionBar",
        props: {
          primaryAction: {
            actionIcon: "library",
            actionText: strings.saveRecipe,
            onPressAction: props.onSaveRecipe,
            waiting: props.waitingSaveRecipe,
            ...showTapHint,
          },
          walkthroughText: saveRecipeText,
        },
      };
    }

    if (haveIngredients) {
      return {
        type: "bottomActionBar",
        props: {
          primaryAction: {
            actionText: strings.startCooking,
            onPressAction: onPressCookNow,
            waiting: waitingCookNow,
            disabled: !haveIngredients,
            ...showTapHint,
          },
          secondaryAction: {
            actionText: strings.addToGroceries,
            onPressAction: onAddToGroceries,
            disabled: !haveIngredients,
            ...showTapHint,
          },
          walkthroughText: libraryActionsText,
        },
      };
    }

    return undefined;
  }, [
    addRecipeFromCurrentUrl,
    haveIngredients,
    isBrowseMode,
    libraryRecipe,
    navToLibraryRecipe,
    onAddToGroceries,
    onDismissStatusBanner,
    onPressCookNow,
    props.onSaveRecipe,
    props.showWalkthrough,
    props.waitingSaveRecipe,
    props.webViewSessionId,
    recipeProcessingTimedOut,
    recipeStatus,
    savedWebViewRecipes,
    signInHelpDomain,
    statusBannerDismissed,
    waitingCookNow,
    waitingSaveRecipeWebView,
    webViewCurrentUrl,
  ]);

  // Calculate height dynamically to enable encapsulation of rendering logic in this component and support dismissal
  // of the status banner. RecipeDetail needs to know the height of the action bar to perform its animations and the only other way
  // to know the height is to move all of rendering logic in this component to the parent. We'll want to revisit this once we
  // clean up RecipeDetail.
  useEffect(() => {
    if (actions) {
      props.onChangeHeight?.({ height: bottomActionBarConstants.height });
    } else {
      props.onChangeHeight?.({ height: 0 });
    }
  }, [!!actions, props.onChangeHeight]);

  const dimensions = useResponsiveDimensions();
  const bottomTabBarHeight = useBottomTabBarHeight();

  // Since this component relies on navigation hooks (i.e. `useBottomTabBarHeight`), we need to provide position hints
  // so that the walkthrough component knows where to render it.
  const walkthroughRectHint = useMemo<WalkthroughStepProps["positionHint"]>(() => {
    const height = bottomActionBarConstants.height;
    const width = dimensions.width;
    const x = 0;
    const y = dimensions.height - bottomTabBarHeight - height;

    return {
      height,
      width,
      x,
      y,
    };
  }, [dimensions.width, dimensions.height, bottomTabBarHeight]);

  const noOp = useCallback(() => {}, []);

  return (
    <>
      {actions?.type === "bottomActionBar" && (
        <WalkthroughStep
          show={props.showWalkthrough}
          onPressButton={props.onWalkthroughNext}
          wobble={false}
          message={actions.props.walkthroughText}
          buttonText="next"
          positionHint={walkthroughRectHint}
          maxWidth="70%"
          onPressChildComponent={noOp}
        >
          <BottomActionBar
            primaryAction={actions.props.primaryAction}
            secondaryAction={actions.props.secondaryAction}
            border={topBorderWidth}
            noBorderRadius
            containerBackgroundColor={props.showWalkthrough ? "transparent" : undefined}
          />
        </WalkthroughStep>
      )}
      {actions?.type === "statusBanner" && <RecipeStatusBanner {...actions.props} />}
      {actions?.type === "signInHelp" && <WebViewSignInHelp {...actions.props} />}
    </>
  );
});
