import React, { RefObject, useCallback, useEffect, useLayoutEffect, useMemo, useRef } from "react";
import { useScreen, withScreenContainer } from "../navigation/ScreenContainer";
import { CookingSessionId } from "@eatbetter/cooking-shared";
import {
  useActiveCookingSessionId,
  useActiveSessionRecipeTitle,
  useCookingSessionAudioEnabled,
  useCookingSessionIds,
  useCookingSessionRecipeId,
  useCookingSessionRecipeScale,
  useCookingSessionRecipeUnitConversion,
  useCookingSessionRecipeYield,
  useFocusInstructionRequest,
  useHaveUnreadNotes,
} from "../lib/cooking/CookingSessionsSelectors";
import { focusInstructionCompleted } from "../lib/cooking/CookingSessionsSlice";
import { ScreenView } from "../components/ScreenView";
import { HeaderProps, useHeaderOffset } from "../components/ScreenHeaders";
import { useDispatch } from "../lib/redux/Redux";
import { InKitchenRecipe, InKitchenRecipeHandle } from "../components/recipes/InKitchenRecipe";
import { Freeze } from "react-freeze";
import { Haptics } from "../components/Haptics";
import { log } from "../Log";
import {
  changeActiveCookingSession,
  cookingSessionScreenUnmountedOrNavedAway,
  setAudioCookingSessionDisabled,
  setAudioCookingSessionEnabled,
} from "../lib/cooking/CookingSessionsThunks";
import { defaultTimeProvider, EpochMs, secondsBetween } from "@eatbetter/common-shared";
import { useCookingSessionFontScale, useSystemSetting } from "../lib/system/SystemSelectors";
import { usePaywallDetection } from "../components/PaywallDetector";
import { WebViewNavigationStateChangeHandler } from "../components/WebView";
import { navTree } from "../navigation/NavTree";
import { displayUnexpectedErrorAndLog } from "../lib/Errors";
import { PixelRatio, View } from "react-native";
import { TBody, THeading1, THeading2, TSecondary } from "../components/Typography";
import { Spacer } from "../components/Spacer";
import { globalStyleConstants } from "../components/GlobalStyles";
import { useExplainerSheet } from "../components/ExplainerSheet";
import { RecipeInKitchenOptions, recipeInKitchenOptionsHeight } from "../components/recipes/RecipeInKitchenOptions";
import { analyticsEvent } from "../lib/analytics/AnalyticsThunks";
import {
  reportCookingSessionMenuOpened,
  reportCookingSessionTextSizeChanged,
  reportCookingSessionTextSizeOpened,
  reportRecipeInKitchenMenuOpened,
} from "../lib/analytics/AnalyticsEvents";
import { Slider } from "../components/Slider";
import { cookingSessionFontScaleUpdated } from "../lib/system/SystemSlice";
import { shareLibraryRecipeLink } from "../lib/share/ShareThunks";
import { HeaderRightProps } from "../components/ScreenHeaderRightButtons";

const strings = {
  explainerSheet: {
    headline: "Cooking with superpowers",
    features: [
      ["💡", "Your screen stays on while you cook"],
      ["✔️", "Check off ingredients as you go"],
      ["⏱️", "Start timers by tapping the magic links"],
      ["🥘", "Cook multiple recipes at once, switch between them with one tap"],
    ],
    cta: "Let's Go",
  },
  textSizeSheet: {
    title: "Text Size",
  },
};

export const RecipeInKitchenScreen = withScreenContainer("RecipeInKitchenScreen", () => {
  const cookingSessionIds = useCookingSessionIds();
  const cookingSessionId = useActiveCookingSessionId();
  const recipeId = useCookingSessionRecipeId(cookingSessionId);

  const focusInstructionRequest = useFocusInstructionRequest();
  const audioEnabled = useCookingSessionAudioEnabled();
  const audioStartTime = useRef<number | undefined>();
  const inKitchenRef = useRef<InKitchenRecipeHandle>(null);
  const screen = useScreen();
  const dispatch = useDispatch();
  const externalShareEnabled = useSystemSetting("externalRecipeShare");
  const haveUnreadNotes = useHaveUnreadNotes(cookingSessionId);

  const { detectPaywallSignin } = usePaywallDetection(recipeId);

  useEffect(() => {
    return () => dispatch(cookingSessionScreenUnmountedOrNavedAway());
  }, []);

  useEffect(() => {
    if (cookingSessionIds.length === 0 && screen.nav.focused) {
      screen.nav.goBack();
    }
  }, [cookingSessionIds.length, screen.nav.goBack, screen.nav.focused]);

  useEffect(() => {
    if (cookingSessionIds[0] && !cookingSessionId) {
      dispatch(changeActiveCookingSession({ cookingSessionId: cookingSessionIds[0]! }));
    }
  }, [cookingSessionIds, cookingSessionId]);

  useLayoutEffect(() => {
    if (focusInstructionRequest) {
      if (focusInstructionRequest.cookingSessionId !== cookingSessionId) {
        // first, focus the cooking session.
        // That will refire this use effect and we can then use the handle (only attached to the active session) to scroll
        if (cookingSessionIds.includes(focusInstructionRequest.cookingSessionId)) {
          dispatch(changeActiveCookingSession({ cookingSessionId: focusInstructionRequest.cookingSessionId }));
        } else {
          log.error(
            `focusInstructionRequest.cookingSessionId not present in cookingSessionIds: ${focusInstructionRequest.cookingSessionId}`
          );
          dispatch(focusInstructionCompleted());
        }
      } else {
        if (
          inKitchenRef.current &&
          inKitchenRef.current.getCookingSessionId() === focusInstructionRequest.cookingSessionId
        ) {
          // I'm not 100% sure why, but things were flakier without the setTimeout - the call would sometimes have no effect
          // and it resulted in an error relating to a reactor that I didnt' track down.
          setTimeout(
            () =>
              inKitchenRef.current!.focusInstruction(
                focusInstructionRequest.sectionId,
                focusInstructionRequest.instructionId
              ),
            0
          );
        } else {
          // NOTE: We are seeing this occassionally in logs, so I suspect there is a bug in the logic above
          log.error(
            `inKitchenRef.current either didn't exist or was an unexpected id: ${inKitchenRef.current?.getCookingSessionId()}`
          );
        }

        // in either case, we've done what we can
        dispatch(focusInstructionCompleted());
      }
    }
  }, [focusInstructionRequest, cookingSessionId, cookingSessionIds]);

  const switchCookingSession = useCallback(() => {
    if (cookingSessionId) {
      const index = cookingSessionIds.indexOf(cookingSessionId);
      if (index >= 0) {
        const newIndex = (index + 1) % cookingSessionIds.length;
        const newId = cookingSessionIds[newIndex];
        if (newId) {
          dispatch(changeActiveCookingSession({ cookingSessionId: newId }));
          Haptics.feedback("itemStatusChanged");
        }
      }
    }
  }, [cookingSessionIds, cookingSessionId, dispatch]);

  const onTapAudio = useCallback(() => {
    if (!audioEnabled) {
      dispatch(setAudioCookingSessionEnabled(inKitchenRef.current?.getCookingSessionId()))
        .then(d => {
          if (d.selectedInstruction && inKitchenRef.current) {
            inKitchenRef.current.focusInstruction(d.selectedInstruction.sectionId, d.selectedInstruction.instructionId);
          }
          audioStartTime.current = defaultTimeProvider();
        })
        .catch(err => {
          log.errorCaught("Unexpected error calling setAudioCookingsessionEnabled", err);
        });
    } else {
      const seconds = audioStartTime.current
        ? Math.floor(secondsBetween(audioStartTime.current as EpochMs, defaultTimeProvider()))
        : undefined;
      audioStartTime.current = undefined;
      dispatch(setAudioCookingSessionDisabled(seconds)).catch(err => {
        log.errorCaught("Unexpected error calling setAudioCookingsessionDisabled", err);
      });
    }
  }, [audioEnabled, dispatch]);

  const onPressShare = useCallback(async () => {
    if (!recipeId) {
      log.error("onPressShareButton: have cooking session ID but no recipe ID. This shouldn't be possible", {
        cookingSessionId,
        recipeId,
      });
      return;
    }

    if (!externalShareEnabled) {
      screen.nav.modal(navTree.get.screens.shareRecipe, { recipeId });
      return;
    }

    // External (web link) share
    try {
      await dispatch(shareLibraryRecipeLink({ recipeId, nav: screen.nav }));
    } catch (err) {
      displayUnexpectedErrorAndLog("Error dispatching shareRecipeLink in RecipeDetail", err, {
        userRecipeId: recipeId,
      });
    }
  }, [screen.nav, externalShareEnabled, recipeId, dispatch]);

  const onPressTextSize = useCallback(() => {
    dispatch(analyticsEvent(reportCookingSessionTextSizeOpened()));
    screen.nav.modal(navTree.get.screens.bottomSheet, {
      content: <TextSizeSheet />,
      height: 160,
    });
  }, [dispatch, screen.nav.modal]);

  const onPressMenu = useCallback(() => {
    if (!recipeId) {
      log.error("onPressMenu: have cooking session ID but no recipe ID. This shouldn't be possible", {
        cookingSessionId,
        recipeId,
      });
      return;
    }

    reportCookingSessionMenuOpened();
    screen.nav.modal(navTree.get.screens.bottomSheet, {
      content: (
        <RecipeInKitchenOptions
          recipeId={recipeId}
          onTapAudio={onTapAudio}
          onTapShare={onPressShare}
          onTapTextSize={onPressTextSize}
        />
      ),
      height: recipeInKitchenOptionsHeight,
    });
    dispatch(analyticsEvent(reportRecipeInKitchenMenuOpened()));
  }, [dispatch, screen.nav.modal, recipeId, onTapAudio, onPressShare, onPressTextSize]);

  // Onbooarding explainer sheet
  useExplainerSheet({
    checkpoint: "cookingSessionStarted",
    content: <ExplainerSheet />,
    closeButtonText: strings.explainerSheet.cta,
    height: 420,
    disableGestureDismissal: true,
    condition: screen.nav.focused,
  });

  if (!cookingSessionId) {
    // if the cooking session ID is undefined, one of two things will happen:
    // it will be set in the useEffect above or
    // if there are no cooking sessions, we will nav back
    return null;
  }

  if (!recipeId) {
    throw new Error("Have cooking session ID but no recipe ID. This shouldn't be possible");
  }

  return React.createElement<Props>(RecipeInKitchenScreenComponent, {
    activeCookingSessionId: cookingSessionId,
    audioEnabled,
    onTapAudio,
    onPressMenu,
    onPressTextSize,
    cookingSessionIds,
    switchCookingSession: cookingSessionIds.length > 1 ? switchCookingSession : undefined,
    selectedInKitchenRef: inKitchenRef,
    onWebViewNavigationStateChanged: detectPaywallSignin,
    haveUnreadNotes,
  });
});

interface Props {
  cookingSessionIds: CookingSessionId[];
  activeCookingSessionId: CookingSessionId;
  audioEnabled: boolean;
  onTapAudio: () => void;
  onPressMenu: () => void;
  onPressTextSize: () => void;
  switchCookingSession?: () => void;
  selectedInKitchenRef: RefObject<InKitchenRecipeHandle>;
  onWebViewNavigationStateChanged?: WebViewNavigationStateChangeHandler;
  haveUnreadNotes?: boolean;
}

const RecipeInKitchenScreenComponent = React.memo((props: Props) => {
  const recipeTitle = useActiveSessionRecipeTitle();
  const recipeYield = useCookingSessionRecipeYield(props.activeCookingSessionId);
  const recipeScale = useCookingSessionRecipeScale(props.activeCookingSessionId);
  const recipeUnits = useCookingSessionRecipeUnitConversion(props.activeCookingSessionId);

  // -------- RENDER STARTS HERE --------

  const screenHeaderAnimationRef = useHeaderOffset();

  const header = useMemo<HeaderProps>(() => {
    const onChangeScale = () => {
      //SCALE-TODO-DISPATCH
    };

    const onChangeUnit = () => {
      //SCALE-TODO-DISPATCH
    };

    const menu: HeaderRightProps = {
      type: "menu",
      onPress: props.onPressMenu,
    };

    const scaleAndConvert: HeaderRightProps = {
      type: "scaleAndConvert",
      scaleAndConvertProps: {
        recipeTitle,
        recipeYield,
        scale: recipeScale,
        onChangeScale,
        unit: recipeUnits,
        onChangeUnit,
      },
    };

    return {
      type: "custom",
      title: recipeTitle ?? "",
      animationConfig: {
        animationProgress: screenHeaderAnimationRef,
        blurBackgroundThreshold: 0,
      },
      right: props.audioEnabled
        ? {
            type: "threeButtons",
            left: {
              type: "speakerOn",
              onPress: props.onTapAudio,
            },
            middle: scaleAndConvert,
            right: menu,
          }
        : {
            type: "twoButtons",
            left: scaleAndConvert,
            right: menu,
          },
    };
  }, [
    recipeTitle,
    screenHeaderAnimationRef,
    props.audioEnabled,
    props.onTapAudio,
    props.onPressMenu,
    recipeScale,
    recipeUnits,
  ]);

  return (
    <ScreenView
      header={header}
      keepAwake
      scrollView={false}
      paddingHorizontal={false}
      paddingVertical={false}
      backgroundColor="white"
    >
      {props.cookingSessionIds.map(id => {
        // We want to keep the selected tab and scroll states, etc. so we can't let the sessions unmount
        // even if they aren't the one being currently viewed.
        // Freeze to the rescue
        return (
          <Freeze freeze={id !== props.activeCookingSessionId} key={id}>
            <InKitchenRecipe
              key={id}
              ref={id === props.activeCookingSessionId ? props.selectedInKitchenRef : undefined}
              cookingSessionId={id}
              screenHeaderAnimationRef={screenHeaderAnimationRef}
              switchCookingSession={props.switchCookingSession}
              onWebViewNavStateChanged={props.onWebViewNavigationStateChanged}
              haveUnreadNotes={props.haveUnreadNotes}
            />
          </Freeze>
        );
      })}
    </ScreenView>
  );
});

const ExplainerSheet = React.memo(() => {
  // The fixed heights in the sub-views reduce the amount of work the layout engine has to do, which can lead to crashes / glitches on the sim
  // (and probably on a real device as well)

  return (
    <View style={{ flex: 1, padding: globalStyleConstants.defaultPadding }}>
      <View style={{ height: 40, marginTop: globalStyleConstants.unitSize }}>
        <THeading1 align="center" numberOfLines={1} adjustsFontSizeToFit>
          {strings.explainerSheet.headline}
        </THeading1>
      </View>
      <Spacer vertical={2} />
      <View style={{ height: 240 }}>
        {strings.explainerSheet.features.map(([bullet, text]) => {
          return (
            <View key={text}>
              <View style={{ flexDirection: "row" }}>
                <TBody>{bullet}</TBody>
                <Spacer horizontal={1} />
                <TBody numberOfLines={2} adjustsFontSizeToFit>
                  {text}
                </TBody>
              </View>
              <Spacer vertical={1.75} />
            </View>
          );
        })}
      </View>
    </View>
  );
});

const TextSizeSheet = React.memo(() => {
  const dispatch = useDispatch();
  const cookingSessionFontScale = useCookingSessionFontScale();
  const systemFontScale = PixelRatio.getFontScale();
  const initialValue = cookingSessionFontScale ?? systemFontScale;

  const onSliderValueChange = useCallback(
    (value: number) => {
      dispatch(analyticsEvent(reportCookingSessionTextSizeChanged({ fontScale: value })));
      dispatch(cookingSessionFontScaleUpdated(value));
    },
    [dispatch]
  );

  return (
    <View style={{ padding: globalStyleConstants.defaultPadding }}>
      <View>
        <TBody align="center">{strings.textSizeSheet.title}</TBody>
      </View>
      <Spacer vertical={1.5} />
      <View style={{ flexDirection: "row", alignItems: "center" }}>
        <TSecondary>{"A"}</TSecondary>
        <Spacer horizontal={1.5} />
        <Slider
          stepCount={6}
          minValue={1}
          maxValue={2}
          initialValue={initialValue}
          onValueChange={onSliderValueChange}
        />
        <Spacer horizontal={1.5} />
        <THeading2>{"A"}</THeading2>
      </View>
    </View>
  );
});
