import { useScreen, withNonNavigableScreenContainer } from "../../navigation/ScreenContainer";
import { ScreenView } from "../../components/ScreenView";
import { Paragraph, ParagraphComponent } from "../../components/LongformText";
import { useDispatch } from "../../lib/redux/Redux";
import { useResponsiveDimensions } from "../../components/Responsive";
import { useSurveyOptions } from "../../components/SurveyOptions";
import { useCallback } from "react";
import { navToNextOnboardingScreen } from "../../navigation/NavThunks";
import { onboardingQuestionAnswered } from "../../lib/system/SystemSlice";
import { onboardingScreenHeader } from "../../components/onboarding/OnboardingScreenHeader";
import { View, Image } from "react-native";
import { OnboardingScreenHeadline } from "../../components/onboarding/OnboardingHeadline";
import { bottomActionBarConstants } from "../../components/BottomActionBar";
import { Spacer } from "../../components/Spacer";
import { OnboardingCtaButton } from "../../components/onboarding/OnboardingCtaButton";

const organizeImage = require("../../assets/onboarding/organize.png");

const strings = {
  headline: "Do you like to organize your recipes?",
  subheadline: [
    {
      type: "text",
      text: "You can use tags to organize and filter your recipes. We use AI to apply some basic tags for you.",
    },
  ] satisfies Paragraph["fragments"],
  surveyOptions: {
    yes: "Yes, I like to keep things tidy",
    no: "No, search is all I need",
  } satisfies Record<"yes" | "no", string>,
};

export const OnboardingOrganizeScreen = withNonNavigableScreenContainer("OnboardingOrganizeScreen", () => {
  const { nav } = useScreen();
  const dispatch = useDispatch();
  const { defaultFontScale } = useResponsiveDimensions();
  const surveyOptions = useSurveyOptions(
    "singleSelect",
    Object.entries(strings.surveyOptions).map(([key, displayText]) => ({ key, displayText }))
  );

  const onNext = useCallback(() => {
    dispatch(
      onboardingQuestionAnswered({
        organize: surveyOptions.selected[0]?.key === ("yes" satisfies keyof typeof strings.surveyOptions),
      })
    );
    dispatch(navToNextOnboardingScreen(nav, "replace"));
  }, [dispatch, surveyOptions.selected, nav.goTo]);

  return (
    <ScreenView scrollView={false} header={onboardingScreenHeader}>
      <View style={{ flex: 1, paddingBottom: bottomActionBarConstants.height, justifyContent: "space-between" }}>
        <View>
          <OnboardingScreenHeadline
            headline={strings.headline}
            subHeadline={() => <ParagraphComponent scale={defaultFontScale} fragments={strings.subheadline} isFirst />}
            paddingBottomUnits={0}
          />
        </View>
        <Spacer vertical={1} />
        <OrganizeImage />
        <Spacer vertical={1} />
        <View>{surveyOptions.renderSurveyOptions()}</View>
      </View>
      <OnboardingCtaButton
        cta="next"
        background="transparent"
        onPress={onNext}
        disabled={surveyOptions.selected.length === 0}
      />
    </ScreenView>
  );
});

const OrganizeImage = () => {
  const { height: screenHeight } = useResponsiveDimensions();

  const aspectRatio = 1054 / 1171;
  const imageHeight = 0.35;

  return (
    <Image
      source={organizeImage}
      resizeMode="contain"
      style={{
        alignSelf: "center",
        width: (imageHeight * screenHeight) / aspectRatio,
        height: imageHeight * screenHeight,
      }}
    />
  );
};
