import React, { useCallback } from "react";
import SliderInternal from "@react-native-community/slider";
import { StyleSheet } from "react-native";
import { Haptics } from "./Haptics";
import { globalStyleColors } from "./GlobalStyles";

interface SliderProps {
  initialValue?: number;
  minValue: number;
  maxValue: number;
  stepCount: number;
  onValueChange: (value: number) => void;
}

export const Slider = React.memo((props: SliderProps) => {
  const onValueChange = useCallback(
    (value: number) => {
      Haptics.feedback("itemStatusChanged");
      props.onValueChange(value);
    },
    [props.onValueChange]
  );

  return (
    <SliderInternal
      style={styles.slider}
      value={props.initialValue ?? props.minValue}
      step={(props.maxValue - props.minValue) / props.stepCount}
      minimumValue={props.minValue}
      maximumValue={props.maxValue}
      tapToSeek
      minimumTrackTintColor={globalStyleColors.colorAccentCool}
      maximumTrackTintColor={globalStyleColors.colorGreyDark}
      onValueChange={onValueChange}
    />
  );
});

const styles = StyleSheet.create({
  slider: {
    flex: 1,
    height: 48,
  },
});
