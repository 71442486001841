import React from "react";
import { View } from "react-native";
import { Text, TextProps } from "../Typography";
import { Opacity } from "../GlobalStyles";
import { RecipeYield } from "@eatbetter/recipes-shared";
import { useScaled } from "../../lib/recipes/UseScaled";
import { UnitConversion } from "@eatbetter/items-shared";
import { ModifiableRecipeText, ModifiableRecipeTextProps } from "./RecipeText";

const strings = {
  recipeYield: "Yield",
};

interface Props {
  yield: RecipeYield;
  recipeScale?: number;
  fontSize?: ModifiableRecipeTextProps["fontSize"];
  italic?: boolean;
  opacity?: Extract<keyof typeof Opacity, "dark" | "opaque">;
}

export const RecipeYieldDisplay = React.memo((props: Props) => {
  const scale = props.recipeScale ?? 1;
  //SCALE-TODO: get from user settings
  const units: UnitConversion = "original";
  const tokens = useScaled(props.yield, scale, units);

  const textProps: Pick<TextProps, "opacity" | "italic" | "numberOfLines"> & Required<Pick<Props, "fontSize">> = {
    fontSize: props.fontSize ?? "secondary",
    opacity: props.opacity ?? "opaque",
    italic: props.italic,
    numberOfLines: 1,
  };

  return (
    <View>
      <Text fontSize={textProps.fontSize}>
        <Text {...textProps} fontSize={textProps.fontSize}>{`${strings.recipeYield}: `}</Text>
        <ModifiableRecipeText {...textProps} fontSize={textProps.fontSize} tokens={tokens} />
      </Text>
    </View>
  );
});
