import { UserId } from "@eatbetter/common-shared";
import { CookingSessionId, CookingSessionIngredientIndex } from "@eatbetter/cooking-shared";
import { Reactor, ReactorResponse } from "../redux/Reactors";
import { loadCookingSessions, updateIngredientStatus, updateSelectedInstructionServer } from "./CookingSessionsThunks";
import { RecipeSectionId } from "@eatbetter/recipes-shared";
import { shouldFetch } from "../redux/ShouldFetch";
import { getReactorResponse } from "../redux/ItemWithUpdates";

const fetchCookingSessionsReactor: Reactor = state => {
  const fetchResult = shouldFetch("cookingSessions.meta", state, s => s.cookingSessions.meta, {
    staleThresholdSeconds: 300,
  });

  if (fetchResult.now) {
    return {
      dispatch: loadCookingSessions(),
    };
  }

  if (fetchResult.laterIn) {
    return {
      kickInMs: fetchResult.laterIn,
    };
  }

  return undefined;
};

const persistIngredientsReactor: Reactor = state => {
  let action: ReactorResponse | undefined;
  let kickInMs: number | undefined;

  Object.entries(state.cookingSessions.cookingSessions).forEach(c => {
    const [cookingSessionId, cookingSession] = c;
    Object.entries(cookingSession.ingredientStatuses).forEach(i => {
      const [sectionId, ingredients] = i;

      ingredients.every((item, index) => {
        const currentAction = getReactorResponse(item, state.system.time, {
          create: () => undefined,
          update: () =>
            updateIngredientStatus(
              cookingSessionId as CookingSessionId,
              sectionId as RecipeSectionId,
              index as CookingSessionIngredientIndex
            ),
        });

        if (!currentAction) {
          return true; // continue
        }

        if (currentAction.dispatch) {
          action = currentAction;
          return false; // break
        }

        if (currentAction.kickInMs && (!kickInMs || currentAction.kickInMs < kickInMs)) {
          kickInMs = currentAction.kickInMs;
        }

        return true;
      });
    });
  });

  if (action) {
    return action;
  }

  if (kickInMs) {
    return { kickInMs };
  }

  return undefined;
};

const persistInstructionsReactor: Reactor = state => {
  let action: ReactorResponse | undefined;
  let kickInMs: number | undefined;

  Object.entries(state.cookingSessions.cookingSessions).forEach(c => {
    const [cookingSessionId, cookingSession] = c;
    Object.entries(cookingSession.selectedInstructions).every(i => {
      const [userId, item] = i;

      const currentAction = getReactorResponse(item, state.system.time, {
        create: () => undefined,
        update: () => updateSelectedInstructionServer(cookingSessionId as CookingSessionId, userId as UserId),
      });

      if (!currentAction) {
        return true; // continue
      }

      if (currentAction.dispatch) {
        action = currentAction;
        return false; // break
      }

      if (currentAction.kickInMs && (!kickInMs || currentAction.kickInMs < kickInMs)) {
        kickInMs = currentAction.kickInMs;
      }

      return true;
    });
  });

  if (action) {
    return action;
  }

  if (kickInMs) {
    return { kickInMs };
  }

  return undefined;
};

export const cookingSessionsReactors = [
  fetchCookingSessionsReactor,
  persistIngredientsReactor,
  persistInstructionsReactor,
];
