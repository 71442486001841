import { StyleSheet, View } from "react-native";
import { Spacer } from "../Spacer";
import { TSecondary } from "../Typography";
import { RecipeCard, UserRecipePressedHandler, recipeCardConstants } from "../recipes/RecipeCards";
import { globalStyleConstants } from "../GlobalStyles";
import React, { useCallback, useState } from "react";
import { RecipeGroceryListItem } from "@eatbetter/lists-shared";
import { useRecipe } from "../../lib/recipes/RecipesSelectors";
import { useScreen } from "../../navigation/ScreenContainer";
import { navTree } from "../../navigation/NavTree";
import { SectionHeading } from "../SectionHeading";
import { useListRecipe } from "../../lib/lists/ListsSelectors";
import { UnitConversion } from "@eatbetter/items-shared";
import { ScalingAndConversionsButton } from "../recipes/ScalingAndConversions";

const strings = {
  sectionHeader: "Source Recipe",
};

const config = {
  sectionHeadingHeight: 21 + globalStyleConstants.unitSize,
  ingredientTextMaxHeight: 88, // 2 lines
  recipeCardHeight: recipeCardConstants.verticalScrollCardHeight + 0.5 * globalStyleConstants.unitSize,
};

export const groceryItemRecipeSourceConstants = {
  height: config.sectionHeadingHeight + config.ingredientTextMaxHeight + config.recipeCardHeight,
};

export const GroceryItemRecipeSource = React.memo((props: { item: RecipeGroceryListItem }) => {
  const recipe = useRecipe(props.item.recipeId);
  const listRecipe = useListRecipe(props.item.recipeInstanceId);
  const screen = useScreen();

  const [recipeScale, setRecipeScale] = useState(listRecipe?.scale ?? 1);
  //SCALE-TODO: get from user settings
  const [recipeUnits, setRecipeUnits] = useState<UnitConversion>("original");

  const onPressCallback = useCallback<UserRecipePressedHandler>(
    recipeId => {
      screen.nav.switchTab("recipesTab", navTree.get.screens.recipeDetail, { recipeId });
    },
    [screen.nav.switchTab]
  );

  const ingredientText = (
    <View style={styles.ingredientText}>
      <Spacer vertical={1} />
      <TSecondary numberOfLines={2} opacity="dark">
        {props.item.recipeText}
      </TSecondary>
      <Spacer vertical={1} />
    </View>
  );

  if (recipe) {
    return (
      <View style={styles.container}>
        <View style={{ flexDirection: "row", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
          <SectionHeading text={strings.sectionHeader} noPadding />
          <ScalingAndConversionsButton
            recipeTitle={listRecipe?.title}
            recipeYield={listRecipe?.recipeYield}
            showLabel
            strokeWidth="medium"
            scale={recipeScale}
            onChangeScale={setRecipeScale}
            unit={recipeUnits}
            onChangeUnit={setRecipeUnits}
          />
        </View>
        <Spacer vertical={1} />
        <RecipeCard
          {...recipe}
          index={0}
          onPress={onPressCallback}
          searchSessionId={undefined}
          showTimeFilter={false}
        />
        <Spacer vertical={1} />
        {ingredientText}
      </View>
    );
  }

  return null;
});

const styles = StyleSheet.create({
  container: {},
  ingredientText: {
    maxHeight: config.ingredientTextMaxHeight,
  },
});
