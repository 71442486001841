import { useSelector } from "../redux/Redux";
import { CookingSession, CookingSessionId } from "@eatbetter/cooking-shared";
import { CookingTimerId, mergeCookingSession } from "./CookingSessionsSlice";
import { createSelector1, createSelector2 } from "../redux/CreateSelector";
import { RootState } from "../redux/RootReducer";
import { RecipeInstructionId, RecipeSectionId, AppUserRecipe, RecipeId, RecipeYield } from "@eatbetter/recipes-shared";
import { mergeItemWithUpdates } from "../redux/ItemWithUpdates";
import memoize from "fast-memoize";
import { filterOutFalsy, UserId } from "@eatbetter/common-shared";
import { useRef } from "react";
import { selectLibraryRecipe } from "../recipes/RecipesSelectors";
import { UnitConversion } from "@eatbetter/items-shared";

export const useInitialCookingSessionsLoadComplete = () =>
  useSelector(s => {
    return s.cookingSessions.cookingSessionIds.length > 0 || s.cookingSessions.meta.lastUpdated;
  });

export const useCookingSessionIds = () => useSelector(s => s.cookingSessions.cookingSessionIds);
export const useActiveCookingSessionId = () => useSelector(s => s.cookingSessions.activeSessionId);
export const useCookingSessionRecipeId = (id?: CookingSessionId) =>
  useSelector(s => {
    if (!id) {
      return undefined;
    }

    const session = s.cookingSessions.cookingSessions[id];
    return session?.sourceRecipe.id;
  });

export const useCookingSessionRecipeFa = (id?: CookingSessionId) =>
  useSelector(s => {
    if (!id) {
      return undefined;
    }

    const session = s.cookingSessions.cookingSessions[id];
    return session?.sourceRecipe.fa;
  });

const selectSession = (s: RootState, sessionId: CookingSessionId) => s.cookingSessions.cookingSessions[sessionId];

const createInstructionTimerSelector = (
  cookingSessionId: CookingSessionId,
  sectionId: RecipeSectionId,
  instructionId?: RecipeInstructionId
) => {
  return createSelector1(
    s => s.cookingSessions.cookingSessions[cookingSessionId]?.timers,
    timers => {
      if (!instructionId) {
        return undefined;
      }

      return timers
        ?.filter(t => t.sectionId === sectionId && t.instructionId === instructionId)
        .sort((a, b) => a.range[0] - b.range[0]);
    }
  );
};

export const useInstructionTimers = (
  cookingSessionId: CookingSessionId,
  sectionId: RecipeSectionId,
  instructionId?: RecipeInstructionId
) => {
  const selector = useRef(createInstructionTimerSelector(cookingSessionId, sectionId, instructionId)).current;
  return useSelector(selector);
};

export const useCookingTimer = (id?: CookingTimerId) =>
  useSelector(s => {
    if (!id) {
      return undefined;
    }

    return s.cookingSessions.timers.entities[id];
  });

export const selectNextCookingTimerId = createSelector1(
  s => s.cookingSessions.timers.entities,
  timerMap => {
    const timers = filterOutFalsy(Object.values(timerMap));

    timers.sort((a, b) => {
      // timers that have been acknowledged are the least interesting
      if (!!a.alertAcknowledged !== !!b.alertAcknowledged) {
        // fun javascript fact: passing true to Number returns 1 and passing false returns 0
        return Number(!!a.alertAcknowledged) - Number(!!b.alertAcknowledged);
      }

      // running timers are more interesting than paused timers
      // note that this means that we will show a running timer that has a larger remaining time
      // over a paused timer with a smaller remaining time. If we want to change this behavior,
      // we'll need to bring the tick into this selector. It also seems strange to show a paused timer
      // when you hvae a running timer. Another option is to show an indicator in the header when
      // there is also a paused timer.
      if (!!a.pausedTime !== !!b.pausedTime) {
        return Number(!!a.pausedTime) - Number(!!b.pausedTime);
      }

      // next, sort by end time.
      return a.endTime - b.endTime;
    });

    return timers[0]?.id;
  }
);

export const useNextCookingTimerId = () => useSelector(selectNextCookingTimerId);

export const useCookingTimerIds = () => useSelector(s => s.cookingSessions.timers.ids as CookingTimerId[]);

export const useActiveSessionRecipeTitle = () =>
  useSelector(s => {
    if (!s.cookingSessions.activeSessionId) {
      return "";
    }

    const session = selectSession(s, s.cookingSessions.activeSessionId);
    return session?.sourceRecipe.title ?? "";
  });

export const useHaveCookingSession = (id: CookingSessionId | undefined) => {
  return useSelector(s => {
    if (!id) {
      return false;
    }

    return !!s.cookingSessions.cookingSessions[id];
  });
};

export const selectCookingSessionId = (
  state: RootState,
  recipeId: RecipeId | undefined
): CookingSessionId | undefined => {
  const libraryRecipe = selectLibraryRecipe(state, recipeId);
  if (!libraryRecipe) {
    return undefined;
  }
  return Object.values(state.cookingSessions.cookingSessions).find(
    session => session.sourceRecipe.id === libraryRecipe.id
  )?.id;
};

export const useCookingSessionId = (recipeId: RecipeId | undefined): CookingSessionId | undefined =>
  useSelector(s => selectCookingSessionId(s, recipeId));

export const useCookingSessionRecipeTitle = (id: CookingSessionId | undefined) => {
  return useSelector(s => {
    if (!id) {
      return undefined;
    }

    return s.cookingSessions.cookingSessions[id]?.sourceRecipe.title;
  });
};

export const useCookingSessionSourceRecipe = (
  cookingSessionId: CookingSessionId | undefined
): AppUserRecipe | undefined => {
  return useSelector(s => {
    if (!cookingSessionId) {
      return undefined;
    }

    const session = s.cookingSessions.cookingSessions[cookingSessionId];
    return session?.sourceRecipe;
  });
};

export const useCookingSessionRecipeYield = (cookingSessionId: CookingSessionId): RecipeYield | undefined => {
  return useSelector(s => {
    const session = s.cookingSessions.cookingSessions[cookingSessionId];
    return session?.sourceRecipe.recipeYield;
  });
};

// https://www.npmjs.com/package/fast-memoize
// fast-memoize calls JSON.stringify on the args to determine the cache key
// functions stringify to null and are essentially removed from the cache key computation.
// So, by passing a getState function, we'll still get a cache hit even if the underlying state object changes.
// This is what we want in this case because business logic dictates that the section IDs can't change
const selectSectionIds = memoize(
  (getState: () => RootState, cookingSessionId: CookingSessionId, type: "ingredients" | "instructions") => {
    const state = getState();
    const session = selectSession(state, cookingSessionId)!;
    return session.sourceRecipe[type].sections.map(s => s.id);
  }
);

export const useHaveUnreadNotes = (cookingSessionId: CookingSessionId | undefined): boolean => {
  return useSelector(s => {
    if (!cookingSessionId) {
      return false;
    }
    const haveNotes = !!selectCookingSessionById(s, cookingSessionId)?.sourceRecipe.notes?.text.trim();
    const viewedNotes = !!s.cookingSessions.cookingSessions[cookingSessionId]?.notesViewed;
    return haveNotes && !viewedNotes;
  });
};

export const useIngredientSectionIds = (cookingSessionId: CookingSessionId | undefined): RecipeSectionId[] => {
  return useSelector(s => (cookingSessionId ? selectSectionIds(() => s, cookingSessionId, "ingredients") : []));
};
export const useInstructionSectionIds = (cookingSessionId: CookingSessionId | undefined): RecipeSectionId[] => {
  return useSelector(s => (cookingSessionId ? selectSectionIds(() => s, cookingSessionId, "instructions") : []));
};

export const useIngredientSectionItemCount = (cookingSessionId: CookingSessionId, sectionIndex: number) => {
  return useSelector(s => {
    const session = selectSession(s, cookingSessionId);
    return session?.sourceRecipe.ingredients.sections[sectionIndex]?.items.length;
  });
};

export const useInstructionSectionItemCount = (
  cookingSessionId: CookingSessionId | undefined,
  sectionIndex: number
) => {
  return useSelector(s => {
    if (!cookingSessionId) {
      return 0;
    }
    const session = selectSession(s, cookingSessionId);
    return session?.sourceRecipe.instructions.sections[sectionIndex]?.items.length;
  });
};

export const useIngredientSectionTitle = (cookingSessionId: CookingSessionId, sectionIndex: number) => {
  return useSelector(s => {
    const session = selectSession(s, cookingSessionId);
    return session?.sourceRecipe.ingredients.sections[sectionIndex]?.title;
  });
};

export const useInstructionSectionTitle = (cookingSessionId: CookingSessionId, sectionIndex: number) => {
  return useSelector(s => {
    const session = selectSession(s, cookingSessionId);
    return session?.sourceRecipe.instructions.sections[sectionIndex]?.title;
  });
};

export const useIngredient = (cookingSessionId: CookingSessionId, sectionIndex: number, ingredientIndex: number) =>
  useSelector(s => {
    const session = selectSession(s, cookingSessionId);
    return session?.sourceRecipe.ingredients.sections[sectionIndex]?.items[ingredientIndex];
  });

export const useIngredientCompleted = (
  cookingSessionId: CookingSessionId,
  sectionId: RecipeSectionId,
  ingredientIndex: number
) =>
  useSelector(s => {
    const session = selectSession(s, cookingSessionId);
    const itemWithUpdates = session?.ingredientStatuses[sectionId]?.[ingredientIndex];
    if (!itemWithUpdates) {
      return false;
    }

    const merged = mergeItemWithUpdates(itemWithUpdates);
    return merged.status === "completed";
  });

export const useCookingSessionAudioEnabled = () => useSelector(s => s.cookingSessions.audioEnabled);

export const useFocusInstructionRequest = () => useSelector(s => s.cookingSessions.focusInstructionRequest);

export const useInstructionText = (
  cookingSessionId: CookingSessionId,
  sectionIndex: number,
  instructionIndex: number
) =>
  useSelector(s => {
    const session = selectSession(s, cookingSessionId);
    return session?.sourceRecipe.instructions.sections[sectionIndex]?.items[instructionIndex]?.text;
  });

export const useInstructionTextById = (
  cookingSessionId: CookingSessionId | undefined,
  sectionId: RecipeSectionId | undefined,
  instructionId: RecipeInstructionId | undefined
) => {
  return useSelector(s => {
    if (!cookingSessionId || !sectionId || !instructionId) {
      return undefined;
    }

    const instructions = s.cookingSessions.cookingSessions[cookingSessionId]?.sourceRecipe.instructions;
    const section = instructions?.sections.find(s => s.id === sectionId);
    const instruction = section?.items.find(i => i.id === instructionId);
    return instruction?.text;
  });
};

export const useInstructionId = (cookingSessionId: CookingSessionId, sectionIndex: number, instructionIndex: number) =>
  useSelector(s => {
    const session = selectSession(s, cookingSessionId);
    return session?.sourceRecipe.instructions.sections[sectionIndex]?.items[instructionIndex]?.id;
  });

export const useCookingSessionRecipeScale = (cookingSessionId: CookingSessionId): number =>
  useSelector(s => {
    const session = selectSession(s, cookingSessionId);
    return session?.scale ?? 1;
  });

export const useCookingSessionRecipeUnitConversion = (cookingSessionId: CookingSessionId): UnitConversion =>
  useSelector(s => {
    const session = selectSession(s, cookingSessionId);
    return session?.unitConversion ?? "original";
  });

export interface UserIdsForInstruction {
  currentUserSelected: boolean;
  otherUserIds: UserId[];
}

// always return the same object for not selected so reference equality checks
// always return true for the case that no users have it selected
const notSelected: UserIdsForInstruction = {
  currentUserSelected: false,
  otherUserIds: [],
};

const selectSelectedInstructions = (s: RootState, id: CookingSessionId) => selectSession(s, id)?.selectedInstructions;

const selectUserIdsForInstructionFactory = memoize(
  (currentUserId: UserId, cookingSessionId: CookingSessionId, sectionId: RecipeSectionId, instructionIndex: number) => {
    return createSelector1(
      s => selectSelectedInstructions(s, cookingSessionId),
      selectedInstructions => {
        const userIds = selectedInstructions
          ? Object.entries(selectedInstructions).flatMap(e => {
              const [userId, instruction] = e;
              // since the updates are the same type as the item we just need to find the lastest update if any exist, ando otherwise use the item

              // note: indexing with -1 currently returns undefined, but the spec specifies non-negative integers, so being safe
              const index = Math.max(instruction.updates.length - 1, 0);
              const relevantInstruction = instruction.updates[index]?.update ?? instruction.item;

              if (relevantInstruction.sectionId === sectionId && relevantInstruction.index === instructionIndex) {
                return [userId as UserId];
              }

              return [];
            })
          : [];

        // for any instructions that have no users, this will stay stable even when the inputs to the
        // reselect selector change. So the selector will be evaluated again, but the output will remain constant
        if (userIds.length === 0) {
          return notSelected;
        }

        const currentUserSelected = userIds.includes(currentUserId);
        const otherUserIds = userIds.filter(i => i !== currentUserId);

        return { currentUserSelected, otherUserIds };
      }
    );
  }
);

export const useUserIdsForInstruction = (
  currentUserId: UserId | undefined,
  cookingSessionId: CookingSessionId,
  sectionId: RecipeSectionId,
  instructionIndex: number
) => {
  return useSelector(s => {
    if (!currentUserId) {
      return { currentUserSelected: false, otherUserIds: [] };
    }

    return selectUserIdsForInstructionFactory(currentUserId, cookingSessionId, sectionId, instructionIndex)(s);
  });
};

export const selectCookingSessionById: (state: RootState, id: CookingSessionId) => CookingSession | undefined =
  createSelector2(
    (_: RootState, id: CookingSessionId) => id,
    s => s.cookingSessions.cookingSessions,
    (id, cookingSessions) => {
      const cookingSessionState = cookingSessions[id];
      if (!cookingSessionState) {
        return undefined;
      }

      return mergeCookingSession(cookingSessionState);
    }
  );

export const selectCookingSessionAndRecipeIds = createSelector1(
  s => s.cookingSessions.cookingSessions,
  sessions => {
    return Object.values(sessions).map(session => {
      return { recipeId: session.sourceRecipe.id, cookingSessionId: session.id, timeStarted: session.timeStarted };
    });
  }
);
